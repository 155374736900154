import React, { useState, useContext, useEffect } from 'react';
import { DefaultAvatar, SmilesLogo } from 'smiles-react-wallet-core/assets';
import { FiChevronDown } from 'react-icons/fi';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { DASHBOARD_CONFIG } from 'smiles-react-wallet-core/config';
import { TutorialContext } from '_context';

import {
  HeaderWrapper,
  LeftDiv,
  RightDiv,
  Logo,
  OptionalTitle,
  UserData,
  Avatar,
  Name,
  Logout,
} from './FakeHeader.styles';
import { PartnerProfileTip } from '_components';
import { NavLink } from 'react-router-dom';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const FakeHeader: React.FC = () => {
  const { tutorialStep } = useContext(TutorialContext)!;
  const [showProfile, setShowProfile] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setShowProfile(tutorialStep === 5 && true);
  }, [tutorialStep]);

  return (
    <HeaderWrapper>
      <LeftDiv>
        <NavLink to="/">
          <Logo src={SmilesLogo} />
        </NavLink>
        <OptionalTitle>{DASHBOARD_CONFIG.partnerTitle}</OptionalTitle>
      </LeftDiv>

      <RightDiv>
        <UserData
          onClick={() => setShowProfile(!showProfile)}
          tutorialStep={tutorialStep}
        >
          <Avatar src={user?.picture ? user.picture : DefaultAvatar} />
          <Name>
            Olá, <br />
            {user?.name}
          </Name>
          {showProfile && (
            <PartnerProfileTip
              sampleUser={{
                name: user?.name,
                email: user?.email,
                role: user?.role,
              }}
            />
          )}
          <FiChevronDown size={25} color="#fff" />
        </UserData>
        <Logout>
          <RiLogoutBoxRLine size={30} />
          <span>Sair</span>
        </Logout>
      </RightDiv>
    </HeaderWrapper>
  );
};

export default FakeHeader;
