import styled from 'styled-components';
import { THEME } from 'smiles-react-wallet-core/config';
import {
  CustomWrapper,
  CustomContainer,
  CustomTriangle,
} from './TutorialBox.types';

export const Wrapper = styled.div<CustomWrapper>`
  display: ${({ isTutorialShowing }) => (isTutorialShowing ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const Container = styled.div<CustomContainer>`
  display: flex;
  position: absolute;
  top: ${({ modalPositions }) => modalPositions && modalPositions.top};
  left: ${({ modalPositions }) => modalPositions && modalPositions.left};
  right: ${({ modalPositions }) => modalPositions && modalPositions.right};
  max-height: 600px;
  width: 430px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${THEME.secondaryColor};
  border: 1px solid ${THEME.primaryColor};
  border-radius: 5px;
  padding: 28px 32px 42px 32px;

  span {
    text-align: center;
    font-weight: 300;
    color: ${THEME.fontColor};
  }

  text {
    fill: transparent;
  }

  img {
    width: 360px;
    height: 230px;
    padding-top: 36px;
  }

  /** Step, Stepper styling */
  .MuiStepper-root {
    padding: 0;
  }

  .MuiStepButton-root {
    padding: 5px;
  }

  .MuiStepIcon-root {
    width: 10px;
    height: 10px;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${THEME.primaryColor};
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;

  svg {
    color: ${THEME.fontColor};
    transition: all ease 0.2s;
  }

  &:hover {
    svg {
      color: ${THEME.primaryColor};
    }
  }
`;

export const Triangle = styled.div<CustomTriangle>`
  content: '';
  position: absolute;
  top: ${({ trianglePositions }) => trianglePositions && trianglePositions.top};
  left: ${({ trianglePositions }) =>
    trianglePositions && trianglePositions.left};
  right: ${({ trianglePositions }) =>
    trianglePositions && trianglePositions.right};
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
  transform: ${({ tutorialStep }) =>
    tutorialStep === 5 ? 'rotate(90deg)' : 'rotate(-90deg)'};
  cursor: default;
`;

export const StepsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button {
    margin: 0;
  }
`;

export const ActionButton = styled.button`
  height: 55px;
  width: 100px;
  background: ${THEME.primaryColor};
  color: ${THEME.secondaryColor};
  font-size: 16px;
  letter-spacing: -0.4px;
  margin-top: 27px;
  cursor: pointer;
  outline: 0;
  border-radius: 3px;
`;
