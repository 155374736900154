import * as API from 'smiles-react-wallet-core/services/api/establishments';
import * as BANK_API from 'smiles-react-wallet-core/services/api/bankData';
import { EstablishmentType } from 'smiles-react-wallet-core/services/types';
import { Dispatch } from 'redux';
import {
  clearEstablishmentActionCreator,
  getAllEstablishmentsStartActionCreator,
  getAllEstablishmentsFinishActionCreator,
  getEstablishmentByIdStartActionCreator,
  getEstablishmentByIdFinishActionCreator,
  saveOrUpdateEstablishmentStartActionCreator,
  saveOrUpdateEstablishmentFinishActionCreator,
  deleteEstablishmentStartActionCreator,
  deleteEstablishmentFinishActionCreator,
} from '../actionCreators';

export const clearEstablishment = () => async (dispatch: Dispatch) =>
  dispatch(clearEstablishmentActionCreator());

export const getAllEstablishments = (
  partnerId: number,
  order?: string,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(getAllEstablishmentsStartActionCreator());

    const { data } = await API.getEstablishments(partnerId, order);

    dispatch(getAllEstablishmentsFinishActionCreator(data));
  } catch (err) {
    dispatch(clearEstablishmentActionCreator());
  }
};

export const getEstablishmentById = (
  partnerId: number,
  establishmentId: number,
  bankDataPermission: boolean,
) => async (dispatch: Dispatch) => {
  dispatch(getEstablishmentByIdStartActionCreator());

  const { data } = await API.getEstablishmentById(partnerId, establishmentId);

  let bankData;
  if (bankDataPermission) {
    const response = await BANK_API.getEstablishmentBankData(
      partnerId,
      establishmentId,
    );
    bankData = response.data;
  }

  const formattedEstablishment = {
    ...data,
    ...bankData,
  };

  dispatch(getEstablishmentByIdFinishActionCreator(formattedEstablishment));
};

const parseEstablishment = (
  establishment: EstablishmentType,
): EstablishmentType => ({
  ...establishment,
  address: {
    ...establishment.address!,
    zipCode: establishment.address!.zipCode.replace('-', ''),
  },
});

export const saveOrUpdateEstablishment = (
  partnerId: number,
  establishment: EstablishmentType,
) => async (dispatch: Dispatch) => {
  dispatch(saveOrUpdateEstablishmentStartActionCreator());

  if (establishment.id && establishment.id > 0) {
    await API.updateEstablishment(partnerId, parseEstablishment(establishment));
  } else {
    //   To allow users to create/edit multiple establishment simultaneously
    //  a temp-id is created (a random negative value), this will clear it.
    establishment.id = 0;
    await API.createEstablishment(partnerId, parseEstablishment(establishment));
  }

  dispatch(saveOrUpdateEstablishmentFinishActionCreator(establishment));
};

export const deleteEstablishment = (
  partnerId: number,
  establishment: EstablishmentType,
) => async (dispatch: Dispatch) => {
  dispatch(deleteEstablishmentStartActionCreator());

  const { data } = await API.deleteEstablishment(partnerId, establishment);
  dispatch(deleteEstablishmentFinishActionCreator(data));
};
