import React from 'react';

interface Tutorial {
  tutorialStep: number;
  setTutorialStep: (value: number) => void;

  isTutorialShowing: boolean;
  setIsTutorialShowing: (value: boolean) => void;
}

export const TutorialContext = React.createContext<Tutorial | undefined>(
  undefined,
);
