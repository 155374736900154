import React, { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { LocalUsersFinish as LocalUsersFinishPage } from '_modules/LocalUsers/screens';

const LocalUsersFinish: React.FC = () => {
  const { status } = useParams<{ status: string }>();
  const { pathname } = useLocation();

  const memoPath = useMemo(() => {
    if (pathname.indexOf('atualizar') > -1) {
      return 'atualizar';
    } else {
      return 'registrar';
    }
  }, [pathname]);

  const memoTitle = useMemo(() => {
    if (status === 'sucesso') {
      if (pathname.indexOf('registrar') > -1) {
        return 'Cadastro realizado com sucesso!';
      } else {
        return 'Dados do usuário foram atualizados com sucesso!';
      }
    } else {
      return 'Ocorreu um problema, por favor tente novamente!';
    }
  }, [pathname, status]);

  return (
    <LocalUsersFinishPage status={status} origin={memoPath} title={memoTitle} />
  );
};

export default LocalUsersFinish;
