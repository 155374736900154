import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const TransactionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);
  border-radius: 3px;
`;

export const Info = styled.div`
  display: flex;
  padding: 30px;

  &:nth-of-type(odd) {
    background: #f5f6f5;
  }
`;

export const Label = styled.strong`
  width: 300px;
  color: ${COLORS.grey};
`;

export const Value = styled.span`
  color: ${COLORS.grey};
`;
