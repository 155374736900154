import styled from 'styled-components';
import { COLORS, SPACING } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px ${SPACING.large}px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px ${COLORS.boxShadowGrey};
`;

export const Message = styled.span`
  font-size: 22px;
  color: ${COLORS.grey};
  font-weight: 500;
  padding-bottom: ${SPACING.large}px;
`;
