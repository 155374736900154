import React from 'react';
import { PartnerStandardLayout } from '_components';

const NotFound: React.FC = () => {
  return (
    <PartnerStandardLayout pageTitle={`Erro 404 - Página não encontrada`}>
      <h1>Não encontramos a página que você procura.</h1>
      <p>
        Verifique o endereço digitado ou use o menu lateral para navegar nas
        nossas opções disponíveis.
      </p>
    </PartnerStandardLayout>
  );
};

export default NotFound;
