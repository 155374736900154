import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import {
  EstablishmentsList,
  EstablishmentsWelcome,
} from '_modules/Establishments/screens';
import { getAllEstablishments } from '_redux/actions';
import { IStore } from '_redux/store';

const EstablishmentsHome: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { establishmentList } = useSelector(
    (state: IStore) => state.establishment,
  );

  useEffect(() => {
    dispatch(getAllEstablishments(user?.partnerId!));
  }, [dispatch, user]);

  return establishmentList ? (
    <EstablishmentsList partnerId={user?.partnerId!} />
  ) : (
    <EstablishmentsWelcome />
  );
};

export default EstablishmentsHome;
