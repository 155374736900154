import styled from 'styled-components'

export const Container = styled.div`
  padding: 43px 20px 35px;
  margin-top: 80px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);

  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 22px;
  }
`;
