/* eslint-disable max-len */
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Formik } from 'formik';
import { QRCode } from 'react-qrcode-logo';
import { MdWarning } from 'react-icons/all';

import { COLORS } from 'smiles-react-wallet-core/config';
import { useRoute } from 'smiles-react-wallet-core/route';
import { Loading, Button } from 'smiles-react-wallet-core/components';
import { Smiles_Qrcode } from 'smiles-react-wallet-core/assets';
import { OrderNewSchema } from 'smiles-react-wallet-core/utils/models';
import * as API from 'smiles-react-wallet-core/services/api/transactions';
import { TransactionType } from 'smiles-react-wallet-core/services/types';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { qrcodeOrder } from 'smiles-react-wallet-core/services/api/transactions';
import { useNetworkConnection } from 'smiles-react-wallet-core/utils/hooks/useNetworkConnection';
import {
  QrcodeRequest,
  QrcodeInfo,
} from 'smiles-react-wallet-core/services/types';

import {
  Container,
  HelpColumn,
  ModalContent,
  ModalFooter,
  QrcodeColumn,
  LoadingWrapper,
} from './OrdersNew.styles';
import OrdersNewTable from '_components/OrdersNewTable';
import {
  ButtonContainer,
  SectionTitle,
} from '_components/OrdersNewForm/OrdersNewForm.styles';
import { OrdersNewForm, Modal, PartnerStandardLayout } from '_components';
import { AxiosResponse } from 'axios';

import ErrorBox from './ErrorBox';

const MdSignalWifiOff = function MdSignalWifiOff() {
  return (
    <svg
      width="28px"
      height="28px"
      style={{ verticalAlign: 'middle', marginLeft: 16 }}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="G00---VENDA-(POS-DIGITAL)"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="G06---VENDA---ERRO-01"
          transform="translate(-489.000000, -1318.000000)"
          fill="#666666"
          fill-rule="nonzero"
        >
          <g id="Group" transform="translate(259.000000, 1282.000000)">
            <path
              d="M243.106383,44.3539652 L237,38.2475822 C239.139265,36.8259188 241.698259,36 244.460348,36 C251.934236,36 258,42.065764 258,49.5396518 C258,52.3017408 257.174081,54.860735 255.752418,57 L253.775629,55.0232108 C254.736944,53.4255319 255.29207,51.5435203 255.29207,49.5396518 C255.29207,45.0038685 252.489362,41.1179884 248.522244,39.5067698 L248.522244,40.0618956 C248.522244,41.5512573 247.303675,42.7698259 245.814313,42.7698259 L243.106383,42.7698259 L243.106383,44.3539652 Z M257,62.0763012 L255.077273,64 L251.981818,60.9029813 C249.827273,62.3491662 247.25,63.1814047 244.468182,63.1814047 C236.940909,63.1814047 230.831818,57.0692269 230.831818,49.5381506 C230.831818,46.7549267 231.663636,44.1763517 233.095455,42.0207175 L230,38.9236988 L231.922727,37 L257,62.0763012 Z M242,57.2201646 C240.742857,57.2201646 239.714286,55.9238683 239.714286,54.3395062 L239.714286,52.899177 L234.24,46 C234.091429,46.8353909 234,47.6851852 234,48.5781893 C234,54.4547325 237.485714,59.2942387 242,60 L242,57.2201646 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const OrdersNew: React.FC = () => {
  const [order, setOrder] = useState<QrcodeInfo | false>(false);
  const [orderId, setOrderId] = useState<string | undefined>();
  const transactionPoll = useRef<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const { navigateTo } = useRoute();
  const { user } = useUser();
  const { online } = useNetworkConnection();

  // small helper for requests and basic catching
  const request = (info: QrcodeRequest) => {
    setLoading(true);
    return qrcodeOrder(info)
      .then((response: AxiosResponse<QrcodeInfo>) => {
        return response.data;
      })
      .catch(() => {
        setUnavailable(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // handle form submit and mount response
  function handleSubmit(values: any) {
    const { value, productType } = values;

    const hasNoInfo =
      user &&
      ((user.partnerId && user.partnerId < 0) ||
        (user.establishmentId && user.establishmentId < 0));
    if (!user || hasNoInfo) {
      setUnavailable(true);
      return;
    }

    const info: QrcodeRequest = {
      productType,
      amount: value,
      generateqr: false,
      partnerId: user?.partnerId || -1,
      establishmentId: user?.establishmentId || -1,
    };

    return request(info).then((receivingOrder: QrcodeInfo | void) => {
      setOrder(receivingOrder || false);
    });
  }

  // handle to trigger the QRCode generation
  function triggerQr() {
    if (!order) {
      return;
    }

    request({
      generateqr: true,
      amount: order.cost,
      partnerId: order.partner.id,
      productType: order.productType,
      establishmentId: order.establishment.id,
    }).then((receivingOrder: QrcodeInfo | void) => {
      if (receivingOrder) {
        setOrder({
          ...order,
          qrcode: receivingOrder.qrcode,
        });

        setOrderId(receivingOrder.orderId);
      }
    });
  }

  // We call this when we need to go back of close the dialog
  function onClear() {
    setOrder({
      ...order,
      qrcode: '',
    } as QrcodeInfo);
  }

  // style for the modal container
  const containerProps = useMemo(() => {
    return {
      style: {
        width: 851,
      },
    };
  }, []);

  // when we succeed on QRCode we start polling for transaction data
  // we handle few errors but the api doesn't help us out.
  const fetchTransaction = useCallback(() => {
    if (!orderId) {
      console.debug(`There's no orderId to get transaction`);
      return;
    }

    return API.getTransactionById(orderId)
      .then((resp: AxiosResponse<TransactionType>) => {
        let restartPoll = true;
        if (resp.data) {
          const status = resp.data.status;
          switch (status) {
            case 'PROCESSED':
              restartPoll = false;
              navigateTo('Venda concluída', { orderId });
              break;
            case 'CANCELED':
              restartPoll = false;
              // we should validate something else here, for sure, but
              // the API doesn't answer with a stable response to keep on track
              // navigateTo('Venda sem saldo', { orderId });
              navigateTo('Venda não autorizada', { orderId });
              break;
            case 'CANCELED_BY_ERROR':
              restartPoll = false;
              // on all other errors we fallback here, we need a small validation
              // on the CANCELED status for funds all others should hit here
              navigateTo('Venda não autorizada', { orderId });
              break;
          }
        }

        if (transactionPoll.current) {
          clearTimeout(transactionPoll.current);
        }

        if (restartPoll) {
          transactionPoll.current = window.setTimeout(fetchTransaction, 20000);
        }
      })
      .catch((err: Error) => {
        console.error(err);
        navigateTo('Venda não disponível');
      });
  }, [orderId, navigateTo]);

  // this is used to retry for the QRCode process
  const retry = useCallback(() => {
    setUnavailable(false);
    fetchTransaction();
  }, [fetchTransaction]);

  // starts the polling when needed
  useEffect(() => {
    if (orderId && !transactionPoll.current) {
      transactionPoll.current = window.setTimeout(fetchTransaction, 20000);
    }

    const clearPolling = () => {
      if (transactionPoll.current) {
        clearTimeout(transactionPoll.current);
      }
      transactionPoll.current = null;
    };

    if (!orderId && transactionPoll.current) {
      clearPolling();
    }

    return clearPolling;
  }, [orderId, fetchTransaction]);

  return (
    <PartnerStandardLayout breadcrumbs={[]} pageTitle="Venda">
      <Container>
        <Formik
          initialValues={{}}
          validationSchema={OrderNewSchema}
          onSubmit={handleSubmit}
          component={OrdersNewForm}
        />

        {loading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}

        {order && (
          <>
            <SectionTitle>Dados da conversão</SectionTitle>
            <OrdersNewTable order={order} />

            {!unavailable && (
              <ButtonContainer>
                <Button
                  className="qrcode"
                  type="submit"
                  onClick={triggerQr}
                  disabled={!online}
                  text="Gerar QR Code"
                />
              </ButtonContainer>
            )}
          </>
        )}

        <Modal
          title=" "
          isOpen={order && !!order.qrcode && !unavailable}
          closeModal={onClear}
          containerProps={containerProps}
        >
          <ModalContent>
            <QrcodeColumn>
              <QRCode
                value={order ? order.qrcode : undefined}
                fgColor={COLORS.black}
                ecLevel="L"
                size={310}
              />
            </QrcodeColumn>
            <HelpColumn>
              <h2>
                Instruções <u>para o Cliente</u>:
              </h2>

              <ol>
                <li>Abra o aplicativo Smiles no celular.</li>
                <li>Toque em Resgate Instantâneo e habilite a câmera.</li>
                <li>
                  Aponte o celular para essa tela para capturar o código e
                  formalizar a compra.
                </li>
              </ol>
            </HelpColumn>
          </ModalContent>

          {!unavailable && online && (
            <ModalFooter>
              <Button onClick={onClear} reverse text="Voltar" />
            </ModalFooter>
          )}
        </Modal>

        {unavailable && (
          <ErrorBox
            title="Sistema indisponível"
            icon={
              <MdWarning style={{ fontSize: 33, verticalAlign: 'middle' }} />
            }
            message="Tentar novamente em instantes"
            tryAgain={retry}
          />
        )}

        {!online && (
          <ErrorBox
            title="Você está offline"
            icon={<MdSignalWifiOff />}
            message="Verifique sua conexão e tente novamente."
          />
        )}
      </Container>
    </PartnerStandardLayout>
  );
};

export default OrdersNew;
