import React, { useCallback } from 'react';
import 'moment/locale/pt-br';
import { TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import NumberFormat from 'react-number-format';

import { OrdersNewFormProps } from './OrdersNewForm.types';
import {
  Container,
  ContainerBorderless,
  ButtonContainer,
  Label,
  Form,
  SectionTitle,
  SectionTitleFloating,
} from './OrdersNewForm.styles';
import { productTypes } from 'smiles-react-wallet-core/utils';
import { Button } from 'smiles-react-wallet-core/components';

interface NumberFormatType {
  id: string;
  inputRef: React.Ref<any>;
  name: string;
  onChange: Function;
}

function NumberFormatCustom(props: NumberFormatType) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            name: props.id,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      isNumericString
      decimalSeparator=","
      decimalScale={2}
      prefix=""
    />
  );
}

function handleResetField(
  setFieldValue: Function,
  fieldId: string,
  fieldValue: string | undefined,
) {
  if (fieldValue === '') {
    setFieldValue(fieldId, undefined);
  }
}

const OrdersNewForm: React.FC<FormikProps<OrdersNewFormProps>> = ({
  errors,
  values,
  handleChange,
  setFieldValue,
  touched,
}) => {
  const onBlur = useCallback(
    (values) => {
      handleResetField(setFieldValue, 'value', values.value);
    },
    [setFieldValue],
  );

  return (
    <Form>
      <ContainerBorderless>
        <div>
          <SectionTitleFloating>
            Informe o produto / serviço:
          </SectionTitleFloating>

          <TextField
            id="productType"
            select
            value={values.productType}
            autoComplete="off"
            spellCheck="false"
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            error={!!errors.productType}
            helperText={errors.productType ? errors.productType : ' '}
          >
            <option value=" ">Selecione...</option>
            {productTypes.map((ot) => (
              <option key={ot.value} value={ot.value}>
                {ot.label}
              </option>
            ))}
          </TextField>
        </div>
      </ContainerBorderless>

      <SectionTitle>Digite o valor da venda:</SectionTitle>

      <Container>
        <div>
          <Label htmlFor="value">R$</Label>
          <div className="inputWrapper">
            <TextField
              id="value"
              placeholder="0,00"
              error={!!errors.value}
              helperText={errors.value ? errors.value : ' '}
              autoComplete="off"
              spellCheck="false"
              value={values.value}
              onBlur={onBlur}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </div>
          <ButtonContainer>
            <Button
              disabled={
                (!!errors.value && !touched.value) ||
                (!!errors.productType && !touched.productType) ||
                Object.keys(values).length === 0
              }
              type="submit"
              text="Aplicar"
            />
          </ButtonContainer>
        </div>
      </Container>
    </Form>
  );
};

export default OrdersNewForm;
