import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div``;

export const ConfirmationCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);
  margin-bottom: 50px;

  span {
    color: ${COLORS.grey};
  }
  span:nth-of-type(2) {
    font-weight: bold;
  }

  .divider-limiter {
    width: 100%;
    max-width: 500px;
    margin: 25px 0;
  }

  button {
  }
`;

export const Title = styled.h1`
  color: ${COLORS.grey};
  margin-bottom: 20px;
`;

export const MiddleContainer = styled.div`
  margin-bottom: 35px;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
`;

export const UserHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  height: 60px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 15px;

  span {
    color: ${COLORS.grey};
    font-weight: 700;
    font-size: 20px;
  }

  svg {
    color: ${COLORS.orange};
    cursor: pointer;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  padding-left: 15px;

  span {
    color: ${COLORS.grey};
  }

  span:nth-of-type(1) {
    font-weight: 700;
    margin-bottom: 5px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
`;
