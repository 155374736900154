import React, { useContext, useMemo } from 'react';
import { useRoute } from 'smiles-react-wallet-core/route';
import { User } from 'smiles-react-wallet-core/authentication/types';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

import { TutorialContext } from '_context';

import {
  Container,
  Triangle,
  ProfileInfo,
  Info,
  Label,
  Text,
  OptionButtonDivider,
  OptionButton,
  ButtonsWrapper,
} from './PartnerProfileTip.styles';
import { formatAccessProfile } from 'smiles-react-wallet-core';

interface ProfileTipProps {
  sampleUser?: Partial<User>;
}

const PartnerProfileTip: React.FC<ProfileTipProps> = ({ sampleUser }) => {
  const { user, hasPermission } = useUser();
  const info = sampleUser || user;

  const { navigateTo, history } = useRoute();
  const { setIsTutorialShowing, tutorialStep } = useContext(TutorialContext)!;

  /** Function that sets isTutorialShowing to true to trigger Tutorial Modals */
  function handleShowTutorial() {
    history.push('/');
    setIsTutorialShowing(true);
  }

  function goToPartnerInfoPage() {
    navigateTo('Dados do parceiro cadastrado');
  }

  const memoProfile = useMemo(() => {
    const tempProfile = formatAccessProfile(info?.role);

    if (tempProfile === 'Master') {
      return 'Parceiro Master';
    } else {
      return tempProfile;
    }
  }, [info]);

  return (
    info && (
      <Container tutorialStep={tutorialStep}>
        <Triangle />

        <ProfileInfo>
          <h1>Meus Dados</h1>

          <Info>
            <Label>Nome</Label>
            <Text>{info.name}</Text>
          </Info>

          <Info>
            <Label>Email</Label>
            <Text>{info.email}</Text>
          </Info>

          <Info>
            <Label>Perfil de Acesso</Label>
            <Text>{memoProfile}</Text>
          </Info>
        </ProfileInfo>
        {hasPermission('read:partner') && (
          <OptionButtonDivider>
            <button type="button" onClick={goToPartnerInfoPage}>
              Dados do Parceiro Cadastrado
            </button>
          </OptionButtonDivider>
        )}
        <ButtonsWrapper>
          <h1>Ajuda</h1>
          <OptionButton onClick={handleShowTutorial}>
            <h1 className="thinnerH1">Conheça a estrutura do portal</h1>
          </OptionButton>

          <a href="/files/manual_resgate_instantaneo_V06.pdf" target="_blank">
            Baixe o Manual de utilização
          </a>
        </ButtonsWrapper>
      </Container>
    )
  );
};

export default PartnerProfileTip;
