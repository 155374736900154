import { IStore } from '_redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { clearErrorActionCreator } from '_redux/actionCreators/applicationActionCreators';
import { useCallback } from 'react';

export default function useError() {
  const dispatch = useDispatch();
  const { error } = useSelector((state: IStore) => state.application);

  const onClose = useCallback(() => {
    dispatch(clearErrorActionCreator());
  }, [dispatch]);

  return { error, onClose };
}
