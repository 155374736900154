import React, { useState, ChangeEvent, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { phoneMask, accessProfiles } from 'smiles-react-wallet-core/utils';
import { Button, Divider, Modal } from 'smiles-react-wallet-core/components';

import { Wrapper, ButtonsGroup } from './LocalUsersForm.styles';
import { IStore } from '_redux/store';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';

type UseParamsType = {
  id: string;
};

const LocalUsersForm: React.FC<FormikProps<LocalUserType>> = (props) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams<UseParamsType>();
  const { establishmentList } = useSelector(
    (state: IStore) => state.establishment,
  );
  const history = useHistory();
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = props;

  function handlePhoneChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldValue('phone', phoneMask(event.target.value), true);
  }

  function handleEstablishmentChange(event: any) {
    const { value } = event.target;

    const estab = establishmentList.find(
      (establishment) => establishment.id === parseInt(value),
    );
    setFieldValue('establishment', estab);
    setFieldValue('establishmentId', value);
  }

  function handleGoBack() {
    history.goBack();
  }

  const memoIsMaster = useMemo(() => {
    if (values.id && values.accessProfile === 'Master') {
      return true;
    } else {
      return false;
    }
  }, [values.id, values.accessProfile]);

  return (
    <Wrapper>
      <Form>
        <TextField
          id="firstName"
          label="Nome"
          disabled={id ? true : false}
          error={!!(touched.firstName && errors.firstName)}
          helperText={errors.firstName ? errors.firstName : ' '}
          autoComplete="off"
          spellCheck="false"
          variant="outlined"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 100 }}
          fullWidth
        />

        <TextField
          id="lastName"
          label="Sobrenome"
          disabled={id ? true : false}
          error={!!(touched.lastName && errors.lastName)}
          helperText={errors.lastName ? errors.lastName : ' '}
          autoComplete="off"
          spellCheck="false"
          variant="outlined"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 100 }}
          fullWidth
        />

        <TextField
          id="accessProfile"
          select
          label="Perfil de Acesso"
          disabled={memoIsMaster && true}
          value={values.accessProfile}
          onChange={handleChange}
          fullWidth
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          error={!!(touched.accessProfile && errors.accessProfile)}
          helperText={errors.accessProfile ? errors.accessProfile : ' '}
        >
          <option value=" ">Selecione...</option>
          {accessProfiles.map((profile) => (
            <option key={profile.value} value={profile.value}>
              {profile.label}
            </option>
          ))}
        </TextField>

        <TextField
          id="establishmentId"
          select
          label="Estabelecimento"
          disabled={memoIsMaster && true}
          fullWidth
          value={values.establishmentId}
          onChange={handleEstablishmentChange}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          error={!!(touched.establishmentId && errors.establishmentId)}
          helperText={errors.establishmentId ? errors.establishmentId : ' '}
        >
          <option value=" ">Selecione...</option>
          {establishmentList.map((establishment) => (
            <option key={establishment.id} value={establishment.id}>
              {establishment.name}
            </option>
          ))}
        </TextField>

        <TextField
          id="email"
          label="E-mail"
          disabled={id ? true : false}
          error={!!(touched.email && errors.email)}
          helperText={errors.email ? errors.email : ' '}
          autoComplete="off"
          spellCheck="false"
          variant="outlined"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 100 }}
          fullWidth
        />

        <TextField
          id="phone"
          label="Telefone"
          disabled={memoIsMaster && true}
          error={!!(touched.phone && errors.phone)}
          helperText={errors.phone ? errors.phone : ' '}
          autoComplete="off"
          spellCheck="false"
          variant="outlined"
          value={values.phone}
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          fullWidth
        />

        <TextField
          id="isActive"
          select
          disabled={memoIsMaster && true}
          label="Status"
          fullWidth
          value={values.isActive}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          error={!!(touched.isActive && errors.isActive)}
          helperText={errors.isActive ? errors.isActive : ' '}
        >
          <option value=" " disabled>
            Selecione...
          </option>
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </TextField>

        <Divider color="#FF5A00" />

        <ButtonsGroup>
          {memoIsMaster ? (
            <Button
              text="Voltar"
              type="button"
              reverse
              onClick={handleGoBack}
            />
          ) : (
            <>
              <Button
                text="Cancelar"
                type="button"
                reverse
                onClick={handleGoBack}
              />
              <Button text={id ? 'Aplicar' : 'Criar'} type="submit" />
            </>
          )}
        </ButtonsGroup>
      </Form>

      <Modal isOpen={open} closeModal={() => setOpen(false)} title="Pronto!">
        <span>
          Instruções para cadastro de senha foram enviados para o e-mail{' '}
          {values.email}.
        </span>
      </Modal>
    </Wrapper>
  );
};

export default LocalUsersForm;
