import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  padding: 30px 20px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);

  .container-title {
    color: ${COLORS.orange};
  }
`;

export const TopFilters = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  max-width: 1056px;

  .inputWrapper {
    margin-right: 15px;
    width: 100%;
  }
`;

export const BottomFilters = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 30px 0;

  .formGroup {
    margin-left: 15px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
    padding: 6px 25px;
    border-radius: 4px;
    border: 1px solid ${COLORS.menuBorderGrey};

    strong {
      margin-right: 15px;
      color: ${COLORS.grey};
    }

    label {
      display: flex;
      align-items: center;
      color: ${COLORS.grey};

      input[type='checkbox'] {
        transform: scale(1.4);
        margin: 0 10px;
      }
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${COLORS.orange};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 4px;
    border: 0;
    color: #fff;
    background: ${COLORS.orange};
    height: 50px;
    width: 120px;
    margin-top: 15px;
    cursor: pointer;

    &:disabled {
      background: ${COLORS.lightGrey};
      cursor: default;
    }
  }
`;
