import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button, Divider, PartnerStandardLayout } from '_components';
import {
  TopContainer,
  BottomContainer,
  CancelledTransactionData,
  OrangeTitle,
  Info,
  GreyTitle,
  Value,
} from './TransactionFinished.styles';
import { IStore } from '_redux/store';
import { useRoute } from 'smiles-react-wallet-core/route';
import { utcDateTime } from 'smiles-react-wallet-core';

const TransactionFinished: React.FC = () => {
  const { navigateTo } = useRoute();
  const {
    transactionDetails,
    cancellationDetails,
    loadingTransactions,
  } = useSelector((state: IStore) => state.transactions);

  function handleTransactionDetails() {
    navigateTo('Detalhes Transação', { id: transactionDetails?.id });
  }

  return (
    <PartnerStandardLayout isLoading={loadingTransactions}>
      <TopContainer>
        <h1>Transação {transactionDetails?.id} cancelada com sucesso!</h1>

        <Divider spacing={50} />

        <Button
          text="Ir para a página de Transação"
          reverse
          onClick={handleTransactionDetails}
        />
      </TopContainer>

      <BottomContainer>
        <h1>Dados do Cancelamento</h1>

        <CancelledTransactionData>
          <Info>
            <OrangeTitle>Transação</OrangeTitle>
            <Value></Value>
          </Info>

          <Info>
            <GreyTitle>ID da Transação:</GreyTitle>
            <Value>{transactionDetails?.id}</Value>
          </Info>

          <Info>
            <OrangeTitle>Cancelamento</OrangeTitle>
            <Value></Value>
          </Info>

          <Info>
            <GreyTitle>Data/hora do Cancelamento:</GreyTitle>
            <Value>
              {utcDateTime(transactionDetails?.updateDate)}
            </Value>
          </Info>

          <Info>
            <GreyTitle>Milhas a expirar:</GreyTitle>
            <Value>{cancellationDetails?.expiringMiles}</Value>
          </Info>

          <Info>
            <GreyTitle>Milhas devolvidas para o cliente:</GreyTitle>
            <Value>
              {parseInt(transactionDetails?.miles!) ||
                0 - cancellationDetails?.expiringMiles! ||
                0}
            </Value>
          </Info>

          <Info>
            <GreyTitle>Motivo do cancelamento:</GreyTitle>
            <Value>{transactionDetails?.cancellationReason}</Value>
          </Info>

          <Info>
            <OrangeTitle>Dados do Operador</OrangeTitle>
            <Value></Value>
          </Info>

          <Info>
            <GreyTitle>Nome:</GreyTitle>
            <Value>
              {transactionDetails?.operator?.firstName +
                ' ' +
                transactionDetails?.operator?.lastName}
            </Value>
          </Info>

          {transactionDetails?.operator?.email && (
            <Info>
              <GreyTitle>E-mail:</GreyTitle>
              <Value>{transactionDetails?.operator?.email}</Value>
            </Info>
          )}
        </CancelledTransactionData>
      </BottomContainer>
    </PartnerStandardLayout>
  );
};

export default TransactionFinished;
