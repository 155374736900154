import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { LocalUserSchema } from 'smiles-react-wallet-core/utils/models';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import { saveOrUpdateLocalUser } from '_redux/actions';
import { LocalUsersForm, PartnerStandardLayout } from '_components';
import { Container } from './LocalUsersRegister.styles';
import { IStore } from '_redux/store';
import { useRoute } from 'smiles-react-wallet-core';

interface LocalUsersRegisterProps {
  partnerId: number;
}

const LocalUsersRegister: React.FC<LocalUsersRegisterProps> = ({
  partnerId,
}) => {
  const { navigateTo } = useRoute();
  const dispatch = useDispatch();
  const { savingLocalUsers } = useSelector((state: IStore) => state.localUsers);

  function handleSubmit(values: LocalUserType) {
    dispatch(
      saveOrUpdateLocalUser(
        navigateTo,
        {
          ...values,
        },
        partnerId,
      ),
    );
  }

  return (
    <PartnerStandardLayout
      breadcrumbs={['Usuários Locais']}
      pageTitle="Criar Usuário"
      isLoading={savingLocalUsers}
    >
      <Container>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            acessProfile: ' ',
            partnerdId: partnerId,
            establishment: undefined,
            establishmentId: undefined,
            email: '',
            phone: '',
            isActive: 1,
          }}
          validationSchema={LocalUserSchema}
          onSubmit={handleSubmit}
          component={LocalUsersForm}
        />
      </Container>
    </PartnerStandardLayout>
  );
};

export default LocalUsersRegister;
