import styled, { css } from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

type Padding = {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  fontWeigth?: boolean;
  smallFont?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 25px 45px 20px;
  width: 100%;
  max-width: 360px;
  border-radius: 3px;
  flex: 1;
  background: ${COLORS.white};
  input {
    padding: 14.5px 14px;
    font-size: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.tabBorder};
    border-radius: 3px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #fff5f5;
    padding: 6px 20px;
    font-family: 'Nunito';
    font-weight: light;
    font-size: 12px;
    color: #ff6868;
    margin: 0;
  }
`;

export const Logo = styled.img`
  align-self: center;
  width: 120px;
  height: 50px;
`;

export const DashboardName = styled.div<Padding>`
  color: ${COLORS.grey6e};
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.67px;
  line-height: 1.25;
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 25px;
    `}
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 28px;
    `}
    ${({ fontWeigth }) =>
    fontWeigth &&
    css`
      font-weight: 700;
    `}
    ${({ smallFont }) =>
    smallFont &&
    css`
      font-size: 16px;
      font-weight: 200;
      letter-spacing: -0.43px;
    `}
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #cecece;
  margin: 30px 0;
`;
