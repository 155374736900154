import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TransactionFinished } from '_modules/Transactions/screens';
import { getTransactionById } from '_redux/actions';

type UseParamsType = {
  id: string;
};

const TransactionsFinishedModule: React.FC = () => {
  const { id } = useParams<UseParamsType>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactionById(id));
  }, [id, dispatch]);

  return <TransactionFinished />;
};

export default TransactionsFinishedModule;
