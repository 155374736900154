import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getAllEstablishments, clearLocalUsers } from '_redux/actions';
import { LocalUsersRegister as LocalUsersRegisterPage } from '_modules/LocalUsers/screens';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const LocalUsersRegister: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useUser();

  useEffect(() => {
    dispatch(clearLocalUsers());
    dispatch(getAllEstablishments(user?.partnerId!));
  }, [dispatch, user]);

  return <LocalUsersRegisterPage partnerId={user?.partnerId!} />;
};

export default LocalUsersRegister;
