import React from 'react';
import { useSelector } from 'react-redux';
import { cepMask } from 'smiles-react-wallet-core';

import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { PartnerStandardLayout } from '_components';
import { IStore } from '_redux/store';

import {
  Container,
  PartnerContainer,
  PartnerHeader,
  PartnerInfo,
  Info,
  InfoName,
  InfoValue,
  Left,
  Middle,
  Right,
} from './InfoPage.styles';

const InfoPage: React.FC = () => {
  const { hasPermission } = useUser();
  const { partnerDetails, loadingPartner } = useSelector(
    (state: IStore) => state.partner,
  );

  return (
    <PartnerStandardLayout
      breadcrumbs={[]}
      pageTitle="Dados do Parceiro Cadastrado"
      isLoading={loadingPartner}
    >
      <Container>
        <PartnerContainer>
          <PartnerHeader>
            <span>{partnerDetails?.name}</span>
          </PartnerHeader>

          <PartnerInfo>
            <Left>
              <Info className="withMarginBottom">
                <InfoName>Razão Social</InfoName>
                <InfoValue>
                  <span>{partnerDetails?.corporateName}</span>
                </InfoValue>
              </Info>

              <Info>
                <InfoName>CNPJ Matriz</InfoName>
                <InfoValue>
                  <span>{partnerDetails?.cnpj}</span>
                </InfoValue>
              </Info>
            </Left>

            <Middle>
              <Info>
                <InfoName>Endereço de Correspondência</InfoName>
                <InfoValue>
                  <span>CEP: {cepMask(partnerDetails?.address?.zipCode)}</span>
                  <span>Logradouro: {partnerDetails?.address?.streetName}</span>
                  <span>Número: {partnerDetails?.address?.number}</span>
                  {partnerDetails?.address?.complement && (
                    <span>
                      Complemento: {partnerDetails?.address?.complement}
                    </span>
                  )}
                  <span>Bairro: {partnerDetails?.address?.neighborhood}</span>
                  <span>Município: {partnerDetails?.address?.city}</span>
                  <span>UF: {partnerDetails?.address?.state}</span>
                </InfoValue>
              </Info>
            </Middle>
            <Right>
              {hasPermission('read:partnerBank') && (
                <Info>
                  <InfoName>Dados Bancários</InfoName>
                  <InfoValue>
                    <span>Banco: {partnerDetails?.bankData?.bankName}</span>
                    <span>CNPJ: {partnerDetails?.bankData?.cnpj}</span>
                    <span>Agência: {partnerDetails?.bankData?.agency}</span>
                    <span>
                      Conta: {partnerDetails?.bankData?.accountNumber}
                    </span>
                    <span>Titular: {partnerDetails?.bankData?.owner}</span>
                  </InfoValue>
                </Info>
              )}
            </Right>
          </PartnerInfo>
        </PartnerContainer>
      </Container>
    </PartnerStandardLayout>
  );
};

export default InfoPage;
