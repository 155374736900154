import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  position: relative;

  h1,
  h2 {
    margin: 50px 0 25px;
    color: ${COLORS.grey};
  }
  h2 {
    font-size: 22px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .customButton {
    align-self: flex-end;

    a {
      text-decoration: none;
      color: ${COLORS.orange};
      padding: 15px 25px;
      border: 1px solid ${COLORS.orange};
      border-radius: 3px;
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
  z-index: 1015;
  background: white;
`;

export const ModalContent = styled.div`
  min-width: 675px;
  padding-left: 88px;
  padding-right: 88px;
  display: flex;
`;

export const QrcodeColumn = styled.div`
  border: 1px solid ${COLORS.orange};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const HelpColumn = styled.div`
  color: ${COLORS.grey};
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 48px;

  h2 {
    margin-bottom: 26px;
    font-size: 22px;
    font-weight: 300;
  }

  ol {
    li {
      list-style: decimal;
      margin-bottom: 22px;
      margin-left: 22px;

      strong {
        font-weight: 700;
      }
    }
  }
`;
export const ModalFooter = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.lighestDivider};
  margin-top: 42px;
  padding-top: 32px;
`;
