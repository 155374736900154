import { MdReceipt } from 'react-icons/md';

import TransactionsDetails from './route-components/TransactionsDetails';
import TransactionsHomeModule from './route-components/TransactionsHome';
import TransactionsFinished from './route-components/TransactionsFinished';
import { Route } from '../../context/types';

export const Transactions: Route[] = [
  {
    name: 'Transações',
    path: '/transacoes',

    showOnMenu: true,
    component: TransactionsHomeModule,
    menuIcon: MdReceipt,
    permissions: ['read:transaction', 'edit:transaction'],
  },
  {
    name: 'Detalhes Transação',
    path: '/transacoes/detalhes/:id',

    showOnMenu: false,
    component: TransactionsDetails,
  },
  {
    name: 'Cancelamento de transação finalizada',
    path: '/transacoes/cancelada/:id',

    showOnMenu: false,
    component: TransactionsFinished,
  },
];
