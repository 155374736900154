import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { Container, StateFilterWrapper } from './EstablishmentsFilter.styles';
import { EstablishmentType } from 'smiles-react-wallet-core/services/types';

type EstablishmentsFilterProps = {
  establishments: EstablishmentType[];
  setFilteredEstablishments: (value: any) => void;
};

const EstablishmentsFilter: React.FC<EstablishmentsFilterProps> = ({
  establishments,
  setFilteredEstablishments,
}) => {
  const [establishmentFilter, setEstablishmentFilter] = useState<
    string | undefined
  >(undefined);
  const [stateFilter, setStateFilter] = useState<string>(' ');

  function handleEstablishmentChange(event: any) {
    setEstablishmentFilter(event.target.value.toLowerCase());
  }

  function handleStateChange(event: any) {
    setStateFilter(event.target.value.toUpperCase());
  }

  const states = establishments
    .map((f) => f.address?.state)
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    /** Filters */
    const filterByEstablishment = (establishment: EstablishmentType) =>
      establishmentFilter
        ? establishment.name.toLowerCase().indexOf(establishmentFilter) >= 0
        : true;

    const filterByState = (establishment: EstablishmentType) =>
      stateFilter !== ' ' ? establishment.address?.state === stateFilter : true;
    /** --- */

    setFilteredEstablishments(
      establishments.filter(filterByEstablishment).filter(filterByState),
    );
  }, [
    establishmentFilter,
    stateFilter,
    establishments,
    setFilteredEstablishments,
  ]);

  return (
    <Container>
      <FormControl>
        <div className="establishment-filter-wapper">
          <TextField
            id="establishment"
            label="Pesquisar estabelecimento"
            value={establishmentFilter}
            onChange={handleEstablishmentChange}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            fullWidth
          />
        </div>
      </FormControl>

      <StateFilterWrapper>
        <InputLabel htmlFor="state">Filtrar por Estado:</InputLabel>

        <FormControl variant="outlined">
          <Select
            id="state"
            onChange={handleStateChange}
            fullWidth
            value={stateFilter}
          >
            <MenuItem value=" ">
              <span className="styled-menuItem">Selecione</span>
            </MenuItem>
            {states.map((state) => (
              <MenuItem value={state}>
                <span className="styled-menuItem">{state}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StateFilterWrapper>
    </Container>
  );
};

export default EstablishmentsFilter;
