import { MdPeople } from 'react-icons/md';

import { EstablishmentsHome, EstablishmentsDetails } from './route-components';
import { Route } from '../../context/types';

export const Establishments: Route[] = [
  {
    name: 'Estabelecimentos',
    path: '/estabelecimentos',

    showOnMenu: true,
    component: EstablishmentsHome,
    menuIcon: MdPeople,
    permissions: [
      'read:establishment',
      'create:establishment',
      'edit:establishment',
    ],
  },
  {
    name: 'Detalhes do Estabelecimento',
    path: '/estabelecimentos/detalhes/:id',

    showOnMenu: false,
    component: EstablishmentsDetails,
  },
];
