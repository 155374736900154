import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

type CustomDiv = {
  itShouldHide: boolean;
};

export const Container = styled.div`
  h1 {
    margin: 50px 0 25px;
    color: ${COLORS.grey};
    font-weight: normal;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .customButton {
    align-self: flex-end;

    a {
      text-decoration: none;
      color: ${COLORS.orange};
      padding: 15px 25px;
      border: 1px solid ${COLORS.orange};
      border-radius: 3px;
    }
  }
`;
