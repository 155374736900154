import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { saveOrUpdateLocalUser } from '_redux/actions';
import { LocalUserSchema } from 'smiles-react-wallet-core/utils/models';
import { LocalUsersForm, PartnerStandardLayout } from '_components';

import { Container } from './LocalUsersUpdate.styles';
import { IStore } from '_redux/store';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import { useRoute } from 'smiles-react-wallet-core';

interface LocalUsersUpdate {
  partnerId: number;
}

const LocalUsersUpdate: React.FC<LocalUsersUpdate> = ({ partnerId }) => {
  const dispatch = useDispatch();
  const { navigateTo } = useRoute();

  const { localUser, savingLocalUsers } = useSelector(
    (state: IStore) => state.localUsers,
  );

  function handleSubmit(values: LocalUserType) {
    if (localUser?.accessProfile !== 'Master') {
      dispatch(
        saveOrUpdateLocalUser(
          navigateTo,
          {
            ...values,
            id: localUser?.id,
          },
          partnerId,
        ),
      );
    }
  }

  return (
    <PartnerStandardLayout
      breadcrumbs={['Usuários Locais']}
      pageTitle="Editar Usuário"
      isLoading={savingLocalUsers}
    >
      <Container>
        {localUser && (
          <Formik
            initialValues={localUser}
            validationSchema={LocalUserSchema}
            onSubmit={handleSubmit}
            component={LocalUsersForm}
          />
        )}
      </Container>
    </PartnerStandardLayout>
  );
};

export default LocalUsersUpdate;
