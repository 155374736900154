import React, { Fragment } from 'react';

import { Container } from './styles';
import useError from '_redux/helpers/useError';
import { ErrorModal } from 'smiles-react-wallet-core/components';

interface LoginWrapperProps {
  children: React.ReactNode;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => {
  const { error, onClose } = useError();
  console.warn(error);
  return (
    <Fragment>
      <ErrorModal error={error} onClose={onClose} />
      <Container>{children}</Container>
    </Fragment>
  );
};

export default LoginWrapper;
