import React from 'react';

import { MdWarning } from 'react-icons/all';

import { Container, Hr } from './styled';
import { PartnerStandardLayout } from '_components';

const OrderNotAuthorized: React.FC = () => (
  <PartnerStandardLayout breadcrumbs={[]}>
    <Container>
      <h1>
        <MdWarning />
      </h1>

      <h2>Transação não autorizada</h2>

      <Hr />

      <h3 className="spaced-bottom">Favor entrar em contato com a smiles.</h3>
    </Container>
  </PartnerStandardLayout>
);

export default OrderNotAuthorized;
