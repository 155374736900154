import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Smiles_Orange } from 'smiles-react-wallet-core/assets';
import { LoginWrapper, Button } from '_components';
import { IStore } from '_redux/store';
import {
  Container,
  Logo,
  DashboardName,
  DividerHr,
} from './CreatePasswordFinish.styles';

const CreatePasswordFinish: React.FC = () => {
  const history = useHistory();
  const { updated, token } = useSelector(
    (state: IStore) => state.resetPassword,
  );

  const handleGoBack = useCallback(() => {
    history.replace('/');
  }, [history]);

  useEffect(() => {
    if (!token) {
      handleGoBack();
    }
  }, [token, handleGoBack]);

  const memoMessage = useMemo(() => {
    if (updated) {
      return 'Senha cadastrada com sucesso!';
    } else {
      return 'Erro durante o cadastro da senha!';
    }
  }, [updated]);

  return (
    <LoginWrapper>
      <Container>
        <Logo src={Smiles_Orange} />
        <DashboardName paddingTop>Portal Parceiro</DashboardName>

        <DashboardName paddingTop smallerFont>
          {memoMessage}
        </DashboardName>

        <DividerHr spacing={40} />

        <Button text="Acessar Portal" onClick={handleGoBack} />
      </Container>
    </LoginWrapper>
  );
};

export default CreatePasswordFinish;
