import React, { useState } from 'react';
import { TutorialContext } from '_context';

interface ProviderProps {
  children: React.ReactNode;
}

const PartnerProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isTutorialShowing, setIsTutorialShowing] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(-1);

  // remover AuthContext depois de configurar auth0
  return (
    <TutorialContext.Provider
      value={{
        tutorialStep,
        setTutorialStep,
        isTutorialShowing,
        setIsTutorialShowing,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};

export default PartnerProvider;
