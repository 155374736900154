import { ResetPasswordGet } from 'smiles-react-wallet-core/services/types';

import {
  RESETPASSWORD_CLEAR,
  RESETPASSWORD_POST_START,
  RESETPASSWORD_POST_FINISH,
  RESETPASSWORD_GET_START,
  RESETPASSWORD_GET_FINISH,
  CHANGEPASSWORD_START,
  CHANGEPASSWORD_FINISH,
} from '../actionTypes';

export const clearResetPasswordActionCreator = () => ({
  type: RESETPASSWORD_CLEAR,
});

export const resetPasswordPostStartActionCreator = () => ({
  type: RESETPASSWORD_POST_START,
});

export const resetPasswordPostFinishActionCreator = (token: string) => ({
  type: RESETPASSWORD_POST_FINISH,
  payload: token,
});

export const resetPasswordGetStartActionCreator = () => ({
  type: RESETPASSWORD_GET_START,
});

export const resetPasswordGetFinishActionCreator = (
  resetData: ResetPasswordGet,
) => ({
  type: RESETPASSWORD_GET_FINISH,
  payload: resetData,
});

export const changePasswordStartActionCreator = () => ({
  type: CHANGEPASSWORD_START,
});

export const changePasswordFinishActionCreator = (unknowData: any) => ({
  type: CHANGEPASSWORD_FINISH,
  payload: unknowData,
});
