import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

type HighlightedItem = {
  tutorialStep: number;
};

export const Container = styled.div<HighlightedItem>`
  display: flex;
  position: absolute;
  cursor: default;
  top: 60px;
  right: ${({ tutorialStep }) => (tutorialStep === 5 ? '-125px' : '45px')};
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 5px;
  z-index: 2;
  padding: 25px 0;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);

  h1 {
    font-size: 14px;
    letter-spacing: -0.4px;
    font-weight: 700;
    color: ${COLORS.orange};
    padding: 0 50px;
  }
`;

export const Triangle = styled.div`
  position: absolute;
  content: '';
  top: -19px;
  margin-left: 20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ccc transparent;
  align-self: center;
  z-index: 2;

  &:after {
    position: absolute;
    content: '';
    top: -9px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #f9f9f9 transparent;
    z-index: 1 !important;
  }
`;

export const ProfileInfo = styled.div``;

export const Info = styled.div`
  padding: 8px 50px;
`;

export const Label = styled.div`
  color: ${COLORS.lightGrey};
  font-size: 12px;
  margin-bottom: 4px;
`;

export const Text = styled.div`
  color: ${COLORS.grey};
  font-size: 14px;
  font-weight: 500;
`;

export const OptionButtonDivider = styled.div`
  padding: 10px 50px;
  margin: 30px 0 20px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;

  button {
    cursor: pointer;
    border: 0;
    background: transparent;
    color: ${COLORS.orange};
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const OptionButton = styled.div`
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .thinnerH1 {
    font-weight: 400;
  }

  a {
    font-weight: 400;
    color: ${COLORS.orange};
    text-decoration: none;
    font-size: 14px;
    padding: 8px 50px;
  }
`;
