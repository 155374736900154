import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useRoute } from 'smiles-react-wallet-core/route';
import { TransactionCancelSchema } from 'smiles-react-wallet-core/utils/models';
import { TransactionCancelType } from 'smiles-react-wallet-core/services/types';

import {
  Button,
  Divider,
  TransactionContainer,
  CancelTransactionForm,
  PartnerStandardLayout,
} from '_components';
import { IStore } from '_redux/store';
import { cancelTransaction } from '_redux/actions';
import {
  ButtonContainer,
  CancelationContainer,
  CancelButtonContainer,
  PartnerPhrases,
  FormWrapper,
  AlertSpan,
} from './TransactionDetails.styles';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import TransactionCancelDetails from '_components/TransactionCancelDetails';
import { utcDateTime } from 'smiles-react-wallet-core';

const TransactionsDetails: React.FC = () => {
  const { user, hasPermission } = useUser();
  const dispatch = useDispatch();
  const { navigateTo } = useRoute();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const {
    cancellationDetails,
    transactionDetails,
    loadingTransactions,
  } = useSelector((state: IStore) => state.transactions);

  function handleButtonClick() {
    navigateTo('Transações');
  }

  function handleSubmit(values: TransactionCancelType) {
    dispatch(
      cancelTransaction(navigateTo, {
        orderId: values.id,
        memberNumber: transactionDetails?.memberNumber!,
        cancellationNote: values.cancellationNote,
        cancellationReason: values.cancellationReason,
        opCancelationName: user?.name!,
        opCancelationMail: user?.email!,
      }),
    );
  }

  function handleCancel() {
    setIsFormVisible(false);
  }

  const createDate = transactionDetails?.createDate!;
  const isAfter = useMemo(() => {
    const now = moment();
    const createdDate = moment(createDate).endOf('day');
    if (now.isAfter(createdDate)) {
      return true;
    } else {
      return false;
    }
  }, [createDate]);

  const allowRoles = [
    'Administrador',
    'Master',
    'Backoffice',
    'SupervisorLoja',
  ];

  return (
    <PartnerStandardLayout
      breadcrumbs={['Transações']}
      pageTitle={transactionDetails && 'Transação ' + transactionDetails.id}
      isLoading={loadingTransactions}
    >
      <ButtonContainer>
        <h1>Dados da Transação</h1>
        <Button
          text="Voltar para Resultados de Busca"
          reverse
          onClick={handleButtonClick}
        />
      </ButtonContainer>

      <TransactionContainer transaction={transactionDetails!} />

      {transactionDetails?.status === 'PROCESSED' ? (
        <CancelationContainer>
          <PartnerPhrases
            isVisible={user?.role !== 'Administrador' ? true : false}
          >
            <span>
              O cancelamento desta transação só será permitida apenas no dia que
              a operação foi realizada.
              <br />
              Transação realizada em:{' '}
              {utcDateTime(transactionDetails?.createDate!)}
            </span>
          </PartnerPhrases>

          <Divider color="#FF5A00" spacing={50} />

          <CancelButtonContainer isVisible={!isFormVisible}>
            <AlertSpan>
              {!allowRoles.includes(user?.role!) || isAfter
                ? 'Para realizar o cancelamento, entre em contato com o administrador da Smiles.'
                : ' '}
            </AlertSpan>

            {hasPermission('edit:transaction') && (
              <Button
                type="button"
                text="Cancelar Transação"
                reverse
                disabled={
                  (transactionDetails?.status !== 'PROCESSED' && true) ||
                  isAfter ||
                  !allowRoles.includes(user?.role!)
                }
                onClick={() => setIsFormVisible(true)}
              />
            )}
          </CancelButtonContainer>

          <FormWrapper isVisible={isFormVisible}>
            <Formik
              initialValues={{
                id: transactionDetails?.id!,
                cancellationReason: 'Selecione',
                cancellationNote: '',
                agreed: false,
              }}
              validationSchema={TransactionCancelSchema}
              onSubmit={handleSubmit}
              component={CancelTransactionForm}
              onReset={handleCancel}
            />
          </FormWrapper>
        </CancelationContainer>
      ) : transactionDetails?.status === 'CANCELED' ||
        transactionDetails?.status === 'CANCELED_BY_ERROR' ||
        transactionDetails?.status === 'CANCELLED' ||
        transactionDetails?.status === 'CANCELLED_BY_ERROR' ? (
        <TransactionCancelDetails
          cancellationDetails={cancellationDetails}
          transactionDetails={transactionDetails}
        />
      ) : null}
    </PartnerStandardLayout>
  );
};

export default TransactionsDetails;
