import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import { ILocalUsersState } from './types';

import {
  ERROR_SHOW,
  LOCALUSERS_CLEAR,
  LOCALUSERS_GET_ALL_FINISH,
  LOCALUSERS_GET_ALL_START,
  LOCALUSERS_GET_BY_ID_FINISH,
  LOCALUSERS_GET_BY_ID_START,
  LOCALUSERS_SAVE_OR_UPDATE_FINISH,
  LOCALUSERS_SAVE_OR_UPDATE_START,
} from '../actionTypes';

const clearLocalUsersReducer = () => ({ ...localUsersInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingLocalUsers: false,
  savingLocalUsers: false,
});

const getAllStartReducer = () => ({
  loadingLocalUsers: true,
});

const getAllFinishReducer = (localUsers: Array<LocalUserType>) => ({
  loadingLocalUsers: false,
  localUsers,
});

const getByIdStartReducer = () => ({
  loadingLocalUsers: true,
  localUser: undefined,
});
const getByIdFinishReducer = (localUser: LocalUserType) => ({
  loadingLocalUsers: false,
  localUser,
});

const saveOrUpdateStartReducer = () => ({ savingLocalUsers: true });
const saveOrUpdateFinishReducer = (localUser: LocalUserType) => ({
  savingLocalUsers: false,
  localUser,
});

export const localUsersInitialState: ILocalUsersState = {
  savingLocalUsers: false,
  loadingLocalUsers: false,
  localUsers: [],
  localUser: undefined,
};

export const localUsersReducer = {
  [ERROR_SHOW]: clearLoading,
  [LOCALUSERS_GET_ALL_START]: getAllStartReducer,
  [LOCALUSERS_GET_ALL_FINISH]: getAllFinishReducer,
  [LOCALUSERS_GET_BY_ID_START]: getByIdStartReducer,
  [LOCALUSERS_GET_BY_ID_FINISH]: getByIdFinishReducer,
  [LOCALUSERS_SAVE_OR_UPDATE_START]: saveOrUpdateStartReducer,
  [LOCALUSERS_SAVE_OR_UPDATE_FINISH]: saveOrUpdateFinishReducer,
  [LOCALUSERS_CLEAR]: clearLocalUsersReducer,
};
