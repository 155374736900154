import {
  TransactionType,
  CheckCancellationType,
  TransactionCancelledType,
} from 'smiles-react-wallet-core/services/types';
import { ITransactionsState } from '../types';

import {
  TRANSACTIONS_CLEAR,
  TRANSACTIONS_GET_ALL_START,
  TRANSACTIONS_GET_ALL_FINISH,
  TRANSACTION_GET_BY_ID_START,
  TRANSACTION_GET_BY_ID_FINISH,
  EXPIRING_MILES_GET_BY_ID_FINISH,
  EXPIRING_MILES_GET_BY_ID_START,
  TRANSACTION_CANCEL_START,
  TRANSACTION_CANCEL_FINISH,
  ERROR_CLEAR,
} from '../actionTypes';

const clearTransactionsReducer = () => ({ ...transactionsInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingTransactions: false,
  loadingCancelTransaction: false,
});

const getAllStartReducer = () => ({
  loadingTransactions: true,
  transactionsList: [],
});

const getAllFinishReducer = (transactionsList: TransactionType[]) => ({
  loadingTransactions: false,
  transactionsList,
});

const getByIdStartReducer = () => ({
  loadingTransactions: true,
  transactionDetails: undefined,
});
const getByIdFinishReducer = (transactionDetails: TransactionType) => ({
  loadingTransactions: false,
  transactionDetails,
});

const getExpiringMilesByIdStartReducer = () => ({
  loadingCancellationDetails: true,
  cancellationDetails: undefined,
});
const getExpiringMilesByIdFinishReducer = (
  cancellationDetails: CheckCancellationType,
) => ({
  loadingCancellationDetails: false,
  cancellationDetails,
});

const cancelTransactionStartReducer = () => ({
  loadingCancelTransaction: true,
  transactionCancelled: undefined,
});
const cancelTransactionFinishReducer = (
  transactionCancelled: TransactionCancelledType,
) => ({
  loadingCancelTransaction: false,
  transactionCancelled,
});

export const transactionsInitialState: ITransactionsState = {
  loadingTransactions: false,
  transactionsList: [],
  transactionDetails: undefined,
  cancellationDetails: undefined,
  loadingCancelTransaction: false,
  transactionCancelled: undefined,
};

export const transactionsReducer = {
  [ERROR_CLEAR]: clearLoading,
  [TRANSACTIONS_CLEAR]: clearTransactionsReducer,
  [TRANSACTIONS_GET_ALL_START]: getAllStartReducer,
  [TRANSACTIONS_GET_ALL_FINISH]: getAllFinishReducer,
  [TRANSACTION_GET_BY_ID_START]: getByIdStartReducer,
  [TRANSACTION_GET_BY_ID_FINISH]: getByIdFinishReducer,
  [TRANSACTION_CANCEL_START]: cancelTransactionStartReducer,
  [TRANSACTION_CANCEL_FINISH]: cancelTransactionFinishReducer,
  [EXPIRING_MILES_GET_BY_ID_START]: getExpiringMilesByIdStartReducer,
  [EXPIRING_MILES_GET_BY_ID_FINISH]: getExpiringMilesByIdFinishReducer,
};
