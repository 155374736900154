import styled, { css } from 'styled-components';
import { MENU } from 'smiles-react-wallet-core/config';
import { NavLink } from 'react-router-dom';

type HightlightedItem = {
  tutorialStep: number;
};

export const MenuWrapper = styled.div`
  width: 300px;
  min-height: 100%;
  background: ${MENU.menuBackground};
  box-shadow: ${MENU.menuRightShadow};
`;

export const ToggleButton = styled.div<HightlightedItem>`
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 10px;
  cursor: pointer;

  ${({ tutorialStep }) =>
    tutorialStep === 4 &&
    css`
      z-index: 6;
    `}

  svg {
    right: -15px;
    position: relative;
    color: ${MENU.normalColor};
    background: ${MENU.menuBackground};
    border: 1px solid ${MENU.menuBorderColor};
    border-radius: 50%;
  }
`;

export const MenuList = styled.ul<HightlightedItem>`
  li:nth-of-type(1) {
    ${({ tutorialStep }) =>
      tutorialStep === 0 &&
      css`
        a {
          z-index: 6;
          color: ${MENU.activeColor};
          fill: ${MENU.activeColor};
          font-weight: ${MENU.activeWeight};
          background: ${MENU.activeBackground};
        }
      `}
  }

  li:nth-of-type(2) {
    ${({ tutorialStep }) =>
      tutorialStep === 1 &&
      css`
        a {
          z-index: 6;
          color: ${MENU.activeColor};
          fill: ${MENU.activeColor};
          font-weight: ${MENU.activeWeight};
          background: ${MENU.activeBackground};
        }
      `}
  }

  li:nth-of-type(3) {
    ${({ tutorialStep }) =>
      tutorialStep === 2 &&
      css`
        a {
          z-index: 6;
          color: ${MENU.activeColor};
          fill: ${MENU.activeColor};
          font-weight: ${MENU.activeWeight};
          background: ${MENU.activeBackground};
        }
      `}
  }

  li:nth-of-type(4) {
    ${({ tutorialStep }) =>
      tutorialStep === 3 &&
      css`
        a {
          z-index: 6;
          color: ${MENU.activeColor};
          fill: ${MENU.activeColor};
          font-weight: ${MENU.activeWeight};
          background: ${MENU.activeBackground};
        }
      `}
  }
`;

const svgIcon = css`
  fill: ${MENU.normalColor};
  width: ${MENU.iconSize};
  height: ${MENU.iconSize};
  fill: inherit;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  line-height: ${MENU.itemHeight};
  padding: 0 11px;

  span {
    padding-left: 13px;
    visibility: visible;
    opacity: 1;
  }
`;

export const CustomNavLink = styled(NavLink)`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 11px;
  height: 45px;
  text-decoration: none;
  flex: 1;
  font-weight: bold;
  font-size: ${MENU.fontSize};
  color: ${MENU.normalColor};
  border-radius: ${MENU.activeItemBorderRadius};

  & svg {
    ${svgIcon}
  }
`;

export const menuItemActive = {
  color: MENU.activeColor,
  fill: MENU.activeColor,
  fontWeight: MENU.activeWeight,
  background: MENU.activeBackground,
  boxShadow: MENU.activeItemShadow,
};
