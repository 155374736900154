import styled, { css } from 'styled-components';
import { DASHBOARD_CONFIG, THEME, UI } from 'smiles-react-wallet-core/config';

type HightlightedItem = {
  tutorialStep: number;
};

export const HeaderWrapper = styled.div`
  ${UI.row}
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_CONFIG.headerHeight};
  background: ${THEME.primaryColor};
  padding: 6px ${DASHBOARD_CONFIG.dashboardHorizontalPadding};
`;

export const LeftDiv = styled.div`
  ${UI.row}
  align-items: center;
`;

export const RightDiv = styled.div`
  ${UI.row}
  align-items: center;
`;

export const Logo = styled.img`
  width: ${DASHBOARD_CONFIG.logoImgWidth};
  height: ${DASHBOARD_CONFIG.logoImgHeight};
  margin-right: 54px;
`;

export const OptionalTitle = styled.h1`
  font-size: 20px;
  letter-spacing: -0.5px;
  font-weight: 600;
  color: ${THEME.secondaryColor};
`;

export const UserData = styled.div<HightlightedItem>`
  ${UI.row}
  margin-left: auto;
  align-items: center;
  cursor: pointer;
  padding: 5px 15px;
  border: 1px solid #fff;
  border-radius: 3px;

  ${({ tutorialStep }) =>
    tutorialStep === 5 &&
    css`
      position: relative;
      background: ${THEME.primaryColor};
      z-index: 6;
    `}
`;

export const Avatar = styled.img`
  width: ${DASHBOARD_CONFIG.avatarSize};
  height: ${DASHBOARD_CONFIG.avatarSize};
  border-radius: 50%;
`;

export const Name = styled.div`
  font-size: 14px;
  letter-spacing: -0.3px;
  padding: 0 25px 0 10px;
  color: ${THEME.secondaryColor};
`;

export const Logout = styled.div`
  ${UI.row}
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  & span,
  svg {
    color: ${THEME.secondaryColor};
    fill: ${THEME.secondaryColor};
  }
  & span {
    font-size: 10px;
    line-height: 14px;
    padding-left: 8px;
  }
`;
