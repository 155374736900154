import styled, { css } from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

type Padding = {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  smaller?: boolean;
};

type RecaptchaWrapper = {
  highlight: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }

  .divider-width {
    margin: 0 10px;
  }

  .MuiFormHelperText-root.Mui-error {
    background-color: #fff5f5;
    padding: 6px 20px;
    font-family: 'Nunito';
    font-weight: light;
    font-size: 12px;
    color: #ff6868;
    margin: 0;
  }

  input {
    font-size: 14px;
  }
`;

export const DashboardName = styled.div<Padding>`
  color: ${COLORS.grey};
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.67px;
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 25px;
    `}
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 28px;
    `}
    ${({ smaller }) =>
    smaller &&
    css`
      font-size: 16px;
      letter-spacing: -0.43px;
    `}
`;

export const RecaptchaWrapper = styled.div<RecaptchaWrapper>`
  display: flex;
  margin: 0 auto 30px auto;
  border: ${({ highlight }) => highlight && '2px solid red'};
  transform: scale(0.9);
  overflow: hidden;
  > div {
    margin: 0 auto;
  }
`;
