import { LocalUserType } from 'smiles-react-wallet-core/services/types';

import {
  LOCALUSERS_CLEAR,
  LOCALUSERS_GET_ALL_START,
  LOCALUSERS_GET_ALL_FINISH,
  LOCALUSERS_GET_BY_ID_START,
  LOCALUSERS_GET_BY_ID_FINISH,
  LOCALUSERS_SAVE_OR_UPDATE_START,
  LOCALUSERS_SAVE_OR_UPDATE_FINISH,
} from '../actionTypes';

export const clearLocalUsersActionCreator = () => ({
  type: LOCALUSERS_CLEAR,
});

export const getAllLocalUsersStartActionCreator = () => ({
  type: LOCALUSERS_GET_ALL_START,
});

export const getAllLocalUsersFinishActionCreator = (
  localUsers: LocalUserType[],
) => ({
  type: LOCALUSERS_GET_ALL_FINISH,
  payload: localUsers,
});

export const getLocalUserByIdStartActionCreator = () => ({
  type: LOCALUSERS_GET_BY_ID_START,
});

export const getLocalUserByIdFinishActionCreator = (
  localUser: LocalUserType,
) => ({
  type: LOCALUSERS_GET_BY_ID_FINISH,
  payload: localUser,
});

export const saveOrUpdateLocalUserStartActionCreator = () => ({
  type: LOCALUSERS_SAVE_OR_UPDATE_START,
});

export const saveOrUpdateLocalUserFinishActionCreator = (
  localUser: LocalUserType,
) => ({
  type: LOCALUSERS_SAVE_OR_UPDATE_FINISH,
  payload: localUser,
});
