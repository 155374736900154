import { MdPerson } from 'react-icons/md';

import LocalUsersHome from './route-components/LocalUsersHome';
import LocalUsersRegister from './route-components/LocalUsersRegister';
import LocalUsersUpdate from './route-components/LocalUsersUpdate';
import LocalUsersFinish from './route-components/LocalUsersFinish';

import { Route } from '../../context/types';

export const LocalUsers: Route[] = [
  {
    name: 'Usuários Locais',
    path: '/usuarios',

    showOnMenu: true,
    component: LocalUsersHome,
    menuIcon: MdPerson,
    permissions: ['read:partnerUser', 'create:partnerUser', 'edit:partnerUser'],
  },
  {
    name: 'Criar Usuário',
    path: '/usuarios/registrar',

    showOnMenu: false,
    component: LocalUsersRegister,
  },
  {
    name: 'Editar Usuário',
    path: '/usuarios/atualizar/:id',

    showOnMenu: false,
    component: LocalUsersUpdate,
  },
  {
    name: 'Criação de usuário finalizada',
    path: '/usuarios/registrar/finalizada/:status',
    showOnMenu: false,
    component: LocalUsersFinish,
  },
  {
    name: 'Atualização de usuário finalizada',
    path: '/usuarios/atualizar/finalizada/:status',
    showOnMenu: false,
    component: LocalUsersFinish,
  },
];
