import { useMemo } from 'react';
import { TransactionType } from 'smiles-react-wallet-core/services/types';

export const customHeaders = [
  { label: 'Nome', key: 'clientName' },
  { label: 'ID Transação', key: 'id' },
  { label: 'Data', key: 'createDate' },
  { label: 'Status', key: 'status' },
  { label: 'Membro', key: 'memberNumber' },
];

export const itemsPerPage = 10;

export const initialOrderBy = {
  column: 'DATE',
  order: 'DESC',
};

export const useMemoPagedList = (
  page: number,
  itemsPerPage: number,
  list: Array<TransactionType>,
) =>
  useMemo(() => {
    const offset = (page - 1) * itemsPerPage;

    return list.slice(offset, offset + itemsPerPage);
  }, [page, list, itemsPerPage]);
