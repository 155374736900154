import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, Divider, PartnerStandardLayout } from '_components';
import { IStore } from '_redux/store';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

import {
  Container,
  ButtonContainer,
  Title,
  EstablishmentContainer,
  EstablishmentHeader,
  EstablishmentInfo,
  Info,
  InfoName,
  InfoValue,
  Left,
  Middle,
  Right,
} from './EstablishmentDetails.styles';
import { cepMask } from 'smiles-react-wallet-core';

const EstablishmentDetails: React.FC = () => {
  const history = useHistory();
  const { hasPermission } = useUser();
  const { establishmentDetails, loadingEstablishment } = useSelector(
    (state: IStore) => state.establishment,
  );

  function handleButtonClick() {
    history.goBack();
  }

  return (
    <PartnerStandardLayout
      isLoading={loadingEstablishment}
      breadcrumbs={['Estabelecimentos']}
      pageTitle={
        establishmentDetails
          ? establishmentDetails.name + ' - Dados do estabelecimento'
          : 'Estabelecimento'
      }
    >
      <Container>
        <ButtonContainer>
          <Button
            reverse
            text="Voltar para lista"
            onClick={handleButtonClick}
          />
        </ButtonContainer>

        <Title>Dados do Estabelecimento</Title>
        <Divider />

        <EstablishmentContainer>
          <EstablishmentHeader>
            <span>{establishmentDetails?.name}</span>
          </EstablishmentHeader>

          <EstablishmentInfo>
            <Left>
              <Info>
                <InfoName>Razão Social</InfoName>
                <InfoValue>
                  <span>{establishmentDetails?.corporateName}</span>
                </InfoValue>
              </Info>

              <Info>
                <InfoName>CNPJ</InfoName>
                <InfoValue>
                  <span>{establishmentDetails?.cnpj}</span>
                </InfoValue>
              </Info>

              <Info>
                <InfoName>Status</InfoName>
                <InfoValue>
                  <span>
                    {establishmentDetails?.isActive ? 'Ativo' : 'Inativo'}
                  </span>
                </InfoValue>
              </Info>
            </Left>
            <Middle>
              <Info>
                <InfoName>Endereço de Correspondência</InfoName>
                <InfoValue>
                  <span>CEP: {cepMask(establishmentDetails?.address?.zipCode)}</span>
                  <span>
                    Logradouro: {establishmentDetails?.address?.streetName}
                  </span>
                  <span>Número: {establishmentDetails?.address?.number}</span>
                  <span>
                    Complemento: {establishmentDetails?.address?.complement}
                  </span>
                  <span>
                    Bairro: {establishmentDetails?.address?.neighborhood}
                  </span>
                  <span>Município: {establishmentDetails?.address?.city}</span>
                  <span>UF: {establishmentDetails?.address?.state}</span>
                </InfoValue>
              </Info>
            </Middle>
            <Right>
              {hasPermission('read:establishmentBank') && (
                <Info>
                  <InfoName>Dados Bancários</InfoName>
                  <InfoValue>
                    <span>
                      Banco: {establishmentDetails?.bankData?.bankName}
                    </span>
                    <span>CNPJ: {establishmentDetails?.bankData?.cnpj}</span>
                    <span>
                      Agência: {establishmentDetails?.bankData?.agency}
                    </span>
                    <span>
                      Conta: {establishmentDetails?.bankData?.accountNumber}
                    </span>
                    <span>
                      Titular: {establishmentDetails?.bankData?.owner}
                    </span>
                    <span>
                      Conta Digital:{' '}
                      {establishmentDetails?.bankData?.digitalAccountId}
                    </span>
                  </InfoValue>
                </Info>
              )}
            </Right>
          </EstablishmentInfo>
        </EstablishmentContainer>
      </Container>
    </PartnerStandardLayout>
  );
};

export default EstablishmentDetails;
