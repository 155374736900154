export const ESTABLISHMENT_CLEAR = 'ESTABLISHMENT_CLEAR';
export const ESTABLISHMENT_GET_ALL_START = 'ESTABLISHMENT_GET_ALL_START';
export const ESTABLISHMENT_GET_ALL_FINISH = 'ESTABLISHMENT_GET_ALL_FINISH';
export const ESTABLISHMENT_GET_BY_ID_START = 'ESTABLISHMENT_GET_BY_ID_START';
export const ESTABLISHMENT_GET_BY_ID_FINISH = 'ESTABLISHMENT_GET_BY_ID_FINISH';
export const ESTABLISHMENT_SAVE_OR_UPDATE_START =
  'ESTABLISHMENT_SAVE_OR_UPDATE_START';
export const ESTABLISHMENT_SAVE_OR_UPDATE_FINISH =
  'ESTABLISHMENT_SAVE_OR_UPDATE_FINISH';
export const ESTABLISHMENT_DELETE_START = 'ESTABLISHMENT_DELETE_START';
export const ESTABLISHMENT_DELETE_FINISH = 'ESTABLISHMENT_DELETE_FINISH';
