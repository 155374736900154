import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

type HightlightContainer = {
  highlight: boolean;
};

export const Container = styled.div``;

export const Title = styled.h1`
  font-size: 25px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 20px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);
  border-radius: 4px;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  strong {
    color: ${COLORS.orange};
    font-size: 14px;
  }

  .select {
    height: 45px;
  }

  .id-strong {
    color: ${COLORS.grey};
    width: 250px;
  }

  .MuiOutlinedInput-root {
    width: 250px;
  }
  .field-wrapper {
    position: relative;

    .floating-chars {
      position: absolute;
      z-index: 2;
      right: 20px;
      bottom: 30px;
      font-size: 12px;
      color: ${COLORS.grey};
    }
  }

  &:nth-of-type(3) {
    align-items: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button:nth-of-type(2) {
    margin-left: 20px;
  }
`;

export const AgreementContainer = styled.div<HightlightContainer>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #f5f7f5;
  border-radius: 3px;
  padding: 30px;
  font-size: 14px;
  margin-top: 30px;
  border: ${({ highlight }) => highlight && '2px solid red'};

  svg {
    color: ${COLORS.orange};
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 35px;

    .heavy-span {
      font-weight: bold;
    }

    .orange-heavy-span {
      font-weight: bold;
      color: ${COLORS.orange};
    }
  }
`;
