import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import {
  ForgotPasswordSchema,
  initialForgotPasswordValues,
} from 'smiles-react-wallet-core/utils/models';
import { ForgotPasswordFormType } from 'smiles-react-wallet-core/services/types';
import {
  LoginWrapper,
  ForgotPasswordForm,
  Divider,
  Button,
} from '_components';
import { Smiles_Orange } from 'smiles-react-wallet-core/assets';
import { postResetPassword, clearResetPassword } from '_redux/actions';

import { Container, Logo, DashboardName } from './ForgotPassword.styles';
import { IStore } from '_redux/store';

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector((state: IStore) => state.resetPassword);

  function handleGoBack() {
    history.goBack();

    dispatch(clearResetPassword());
  }

  function handleSubmit(values: ForgotPasswordFormType) {
    dispatch(postResetPassword(values.email!, values.recaptchaStatus!));
  }

  return (
    <LoginWrapper>
      <Container>
        <Logo src={Smiles_Orange} />
        <DashboardName paddingTop>Portal Parceiro</DashboardName>

        {!token ? (
          <Formik
            initialValues={initialForgotPasswordValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
            component={ForgotPasswordForm}
          />
        ) : (
          <>
            <DashboardName paddingTop smallerFont>
              Falta pouco, enviamos um e-mail com o link para você recuperar a
              senha.
            </DashboardName>
            <Divider spacing={25} />

            <Button
              block
              type="button"
              onClick={handleGoBack}
              text="Voltar para Login"
            />
          </>
        )}
      </Container>
    </LoginWrapper>
  );
};

export default ForgotPassword;
