import {
  Dashboard,
  Establishments,
  InfoModule,
  LocalUsers,
  Orders,
  Transactions,
} from '_modules';

/**
 * Icons from https://react-icons.github.io/react-icons/
 */
export const ROUTES = [
  ...LocalUsers,
  ...Establishments,
  ...Dashboard,
  ...InfoModule,
  ...Transactions,
  ...Orders,
];
