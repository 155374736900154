import styled, { css } from 'styled-components';
import { COLORS, THEME } from 'smiles-react-wallet-core/config';
import { Divider } from 'smiles-react-wallet-core/components';

type Padding = {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  smallerFont?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 25px 45px 25px;
  width: 100%;
  max-width: 360px;
  border-radius: 3px;
  flex: 1;
  background: ${COLORS.white};
`;

export const Logo = styled.img`
  align-self: center;
  width: 120px;
  height: 50px;
`;

export const DashboardName = styled.div<Padding>`
  color: ${THEME.fontColor};
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.43px;
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 25px;
    `};
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 28px;
    `}
  ${({ smallerFont }) =>
    smallerFont &&
    css`
      font-size: 18px;
      font-weight: 300;
      font-family: 'Nunito';
    `}
`;

export const DividerHr = styled(Divider)`
  margin-left: -25px;
  margin-right: -25px;
  width: auto;
`;
