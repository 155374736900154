import { combineReducers } from 'redux';

import {
  establishmentReducer,
  establishmentInitialState,
} from './establishmentReducer';
import { partnerReducer, partnerInitialState } from './partnerReducer';
import {
  partnerUserReducer,
  partnerUserInitialState,
} from './partnerUserReducer';
import {
  transactionsReducer,
  transactionsInitialState,
} from './transactionsReducer';
import { wizardReducer, wizardInitialState } from './wizardReducer';
import { localUsersReducer, localUsersInitialState } from './localUsersReducer';
import {
  resetPasswordInitialState,
  resetPasswordReducer,
} from './resetPasswordReducer';
import {
  applicationInitialState,
  applicationReducer,
} from './applicationReducer';

const simplifiedReducers = (reducer, initialState) => (state, action) =>
  reducer[action.type]
    ? {
        ...(state || initialState),
        ...reducer[action.type](action.payload, state || initialState),
      }
    : { ...(state || initialState) };

export default combineReducers({
  application: simplifiedReducers(applicationReducer, applicationInitialState),
  establishment: simplifiedReducers(
    establishmentReducer,
    establishmentInitialState,
  ),
  partner: simplifiedReducers(partnerReducer, partnerInitialState),
  partnerUser: simplifiedReducers(partnerUserReducer, partnerUserInitialState),
  wizard: simplifiedReducers(wizardReducer, wizardInitialState),
  localUsers: simplifiedReducers(localUsersReducer, localUsersInitialState),
  transactions: simplifiedReducers(
    transactionsReducer,
    transactionsInitialState,
  ),
  resetPassword: simplifiedReducers(
    resetPasswordReducer,
    resetPasswordInitialState,
  ),
});
