import React from 'react';

import { TransactionsList } from '_modules/Transactions/screens';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const TransactionsHomeModule: React.FC = () => {
  const { user } = useUser();

  return <TransactionsList partnerId={user?.partnerId!.toString()} />;
};

export default TransactionsHomeModule;
