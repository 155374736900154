import styled from 'styled-components';
import { COLORS, THEME } from 'smiles-react-wallet-core/config';

type RecaptchaWrapper = {
  highlight: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 555px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  flex: 1;
  background: ${COLORS.white};
  margin-top: 15px;

  .MuiFormHelperText-root {
    margin-bottom: 30px;
    color: transparent;
  }

  button {
    width: 100%;
  }

  .MuiFormControl-root {
    min-height: 77px;
    margin-bottom: 12px;
  }
`;

export const PasswordValidations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  font-size: 14px;
  letter-spacing: -0.37px;
  color: ${THEME.fontColor};

  span + span {
    margin-top: 3px;
  }
`;

export const ValidationSpan = styled.span`
  &.invalid {
    color: ${COLORS.lightRed};
  }

  &.valid {
    color: green;
  }
`;

export const RecaptchaWrapper = styled.div<RecaptchaWrapper>`
  display: flex;
  margin: 0 0 30px 0;
  border: ${({ highlight }) => highlight && `2px solid ${COLORS.lightRed}`};
  transform: scale(0.9);
  overflow: hidden;
  > div {
    margin: 0 auto;
  }
`;

export const SendButton = styled.button`
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 3px;
  background: ${COLORS.orange};
  color: ${COLORS.white};
  cursor: pointer;
  outline: 0;

  :disabled {
    border: 1px solid ${COLORS.menuBorderGrey};
    background: ${COLORS.snow};
    color: ${COLORS.menuBorderGrey};
    cursor: not-allowed;
  }
`;
