import { DashboardHome } from './components//DashboardHome';
import { Route } from '../../context/types';

export const Dashboard: Route[] = [
  {
    name: 'Home',
    path: '/',

    component: DashboardHome,
    showOnMenu: false,
  },
];
