import styled from 'styled-components';
import { UI } from 'smiles-react-wallet-core/config';
import { BG_IMAGE } from 'smiles-react-wallet-core/assets';

export const Container = styled.div`
  ${UI.centerFlex}
  width: 100%;
  height: 100%;
  background: url(${BG_IMAGE});
  background-size: 100% 100%;
  background-color: #000;
`;
