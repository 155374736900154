import styled from 'styled-components';
import { SPACING, COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  .space-between {
    display: flex;
  }

  .name-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const ProfileFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid ${COLORS.menuBorderGrey};
  margin-left: ${SPACING.large}px;
  padding-left: ${SPACING.normal}px;
  width: 300px;

  label {
    font-weight: 600;
    margin-right: 10px;
  }

  .menuItem-accessProfile {
    color: ${COLORS.lightGrey};
  }

  .MuiSelect-root {
    width: 220px;
  }
`;
