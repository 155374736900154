import { PartnerType } from 'smiles-react-wallet-core/services/types';
import { IPartnerState } from '../types';

import {
  PARTNER_CLEAR,
  PARTNER_GET_ALL_START,
  PARTNER_GET_ALL_FINISH,
  PARTNER_GET_BY_ID_START,
  PARTNER_GET_BY_ID_FINISH,
  PARTNER_SAVE_OR_UPDATE_START,
  PARTNER_SAVE_OR_UPDATE_FINISH,
  PARTNER_DELETE_START,
  PARTNER_DELETE_FINISH,
  ERROR_CLEAR,
} from '../actionTypes';

const clearPartnerReducer = () => ({ ...partnerInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingPartner: false,
  savingPartner: false,
});

const getAllStartReducer = () => ({
  loadingPartner: true,
  partnerList: [],
});
const getAllFinishReducer = (partnerList: Array<PartnerType>) => ({
  loadingPartner: false,
  partnerList,
});

const getByIdStartReducer = () => ({
  loadingPartner: true,
  partnerDetails: undefined,
});
const getByIdFinishReducer = (partnerDetails: PartnerType) => ({
  loadingPartner: false,
  partnerDetails,
});

const saveOrUpdateStartReducer = () => ({ savingPartner: true });
const saveOrUpdateFinishReducer = (partnerDetails: PartnerType) => ({
  savingPartner: false,
  partnerDetails,
});

const deleteStartReducer = () => ({ savingPartner: true });
const deleteFinishReducer = (partnerDetails: PartnerType) => ({
  savingPartner: false,
  partnerDetails,
});

export const partnerInitialState: IPartnerState = {
  loadingPartner: false,
  savingPartner: false,
  partnerDetails: undefined,
  partnerList: [],
};

export const partnerReducer = {
  [ERROR_CLEAR]: clearLoading,
  [PARTNER_CLEAR]: clearPartnerReducer,
  [PARTNER_GET_ALL_START]: getAllStartReducer,
  [PARTNER_GET_ALL_FINISH]: getAllFinishReducer,
  [PARTNER_GET_BY_ID_START]: getByIdStartReducer,
  [PARTNER_GET_BY_ID_FINISH]: getByIdFinishReducer,
  [PARTNER_SAVE_OR_UPDATE_START]: saveOrUpdateStartReducer,
  [PARTNER_SAVE_OR_UPDATE_FINISH]: saveOrUpdateFinishReducer,
  [PARTNER_DELETE_START]: deleteStartReducer,
  [PARTNER_DELETE_FINISH]: deleteFinishReducer,
};
