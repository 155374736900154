import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoute } from 'smiles-react-wallet-core/route';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import { formatAccessProfile } from 'smiles-react-wallet-core/utils';

import {
  LocalUsersFilter,
  EnhancedListing,
  Pagination,
  PartnerStandardLayout,
} from '_components';
import { IStore } from '_redux/store';
import { getAllLocalUsers } from '_redux/actions';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const initialOrderBy = {
  column: 'NAME',
  order: 'ASC',
};

interface LocalUsersListProps {
  partnerId: number;
}

const itemsPerPage = 10;

const LocalUsersList: React.FC<LocalUsersListProps> = ({ partnerId }) => {
  const { navigateTo } = useRoute();
  const { hasPermission } = useUser();
  const dispatch = useDispatch();
  const { localUsers, loadingLocalUsers } = useSelector(
    (state: IStore) => state.localUsers,
  );
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [page, setPage] = useState(1);
  const [filteredLocalUsers, setFilteredLocalUsers] = useState<LocalUserType[]>(
    localUsers,
  );

  function handleDetails(id: number) {
    navigateTo('Editar Usuário', { id });
  }

  useEffect(() => {
    setFilteredLocalUsers(localUsers);
  }, [dispatch, localUsers]);

  useEffect(() => {
    if (orderBy !== initialOrderBy) {
      setPage(1);
      const order = orderBy.column.toUpperCase().concat(',', orderBy.order);
      dispatch(getAllLocalUsers(partnerId, order));
    }
  }, [orderBy, setOrderBy, dispatch, partnerId]);

  /** Paged transactionsList using useMemo */
  const memoizedPagedLocalUsersList = useMemo(() => {
    const offset = (page - 1) * itemsPerPage;

    return filteredLocalUsers.slice(offset, offset + itemsPerPage);
  }, [page, filteredLocalUsers]);

  return (
    <PartnerStandardLayout
      pageTitle="Usuários Locais"
      isLoading={loadingLocalUsers}
    >
      <LocalUsersFilter
        localUsers={localUsers}
        setFilteredLocalUsers={setFilteredLocalUsers}
      />

      <EnhancedListing
        list={memoizedPagedLocalUsersList}
        headers={{
          name: 'Usuário Local',
          accessProfile: 'Perfil',
          email: 'E-mail',
          phone: 'Telefone',
          isactive: 'Status',
        }}
        fieldParser={{
          name: ({ firstName, lastName }) => `${firstName} ${lastName}`,
          isactive: ({ isActive }) => {
            return isActive ? 'Ativo' : 'Inativo';
          },
          accessProfile: ({ accessProfile }) =>
            `${formatAccessProfile(accessProfile)}`,
        }}
        onClickDetails={({ id }: LocalUserType) => handleDetails(id!)}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        hasPermission={hasPermission('edit:partnerUser')}
      />

      <Pagination
        itShouldHide={filteredLocalUsers.length < itemsPerPage ? true : false}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        listLength={filteredLocalUsers.length}
      />
    </PartnerStandardLayout>
  );
};

export default LocalUsersList;
