import React from 'react';
import moment from 'moment';

import {
  CheckCancellationType,
  TransactionType,
} from 'smiles-react-wallet-core/services/types';

import {
  BottomContainer,
  CancelledTransactionData,
  GreyTitle,
  OrangeTitle,
  InfoSection,
  Info,
  Value,
} from '_modules/Transactions/screens/TransactionFinished/TransactionFinished.styles';
import { utcDateTime } from 'smiles-react-wallet-core';

type TransactionContainerProps = {
  transactionDetails?: TransactionType;
  cancellationDetails?: CheckCancellationType;
};

const TransactionCancelDetails: React.FC<TransactionContainerProps> = ({
  transactionDetails,
  cancellationDetails,
}) => {
  return (
    <BottomContainer>
      <h1>Dados do Cancelamento</h1>

      <CancelledTransactionData>
        <InfoSection>
          <OrangeTitle>Transação</OrangeTitle>
          <Value></Value>
        </InfoSection>

        <Info>
          <GreyTitle>ID da Transação:</GreyTitle>
          <Value>{transactionDetails?.id}</Value>
        </Info>

        <InfoSection>
          <OrangeTitle>Cancelamento</OrangeTitle>
          <Value></Value>
        </InfoSection>

        <Info>
          <GreyTitle>Data/hora do Cancelamento:</GreyTitle>
          <Value>
            {transactionDetails?.canceledDate
              ? utcDateTime(transactionDetails?.canceledDate)
              : ''}
          </Value>
        </Info>

        <Info>
          <GreyTitle>Milhas a expirar:</GreyTitle>
          <Value>{cancellationDetails?.expiringMiles}</Value>
        </Info>

        {transactionDetails?.miles &&
          (cancellationDetails?.expiringMiles ||
            transactionDetails?.expiringMiles) ? (
          <Info>
            <GreyTitle>Milhas devolvidas para o cliente:</GreyTitle>
            <Value>
              {parseInt(transactionDetails?.miles!) ||
                0 -
                (cancellationDetails?.expiringMiles ||
                  transactionDetails?.expiringMiles ||
                  0) ||
                0}
            </Value>
          </Info>
        ) : null}

        <Info>
          <GreyTitle>Motivo do cancelamento:</GreyTitle>
          <Value>{transactionDetails?.cancellationReason}</Value>
        </Info>

        <Info>
          <GreyTitle>Observação:</GreyTitle>
          <Value>{transactionDetails?.cancellationNote}</Value>
        </Info>

        <InfoSection>
          <OrangeTitle>Dados do Operador</OrangeTitle>
          <Value></Value>
        </InfoSection>

        {transactionDetails?.opCancelationName ?
          <Info>
            <GreyTitle>Nome:</GreyTitle>
            <Value>{transactionDetails?.opCancelationName}</Value>
          </Info>
          : <Info>
            <GreyTitle>Nome:</GreyTitle>
            <Value>
              {transactionDetails?.operator?.firstName +
                ' ' +
                transactionDetails?.operator?.lastName}
            </Value>
          </Info>
        }

        {transactionDetails?.opCancelationMail ?
          <Info>
            <GreyTitle>E-mail:</GreyTitle>
            <Value>{transactionDetails?.opCancelationMail}</Value>
          </Info>
          : transactionDetails?.operator?.email && (
            <Info>
              <GreyTitle>E-mail:</GreyTitle>
              <Value>{transactionDetails?.operator?.email}</Value>
            </Info>
          )}
      </CancelledTransactionData>
    </BottomContainer>
  );
};

export default TransactionCancelDetails;
