import React from 'react';
import { PartnerStandardLayout } from '_components';
import { MdWarning } from 'react-icons/all';
import { Container, Hr } from './styled';

const SystemUnavailable: React.FC = () => {
  return (
    <PartnerStandardLayout pageTitle="">
      <Container>
        <h1>
          <MdWarning />
        </h1>

        <h2>Sistema Indisponível</h2>

        <Hr />

        <h3 className="spaced-bottom">
          O sistema no momento encontra-se indisponivel, aguarde alguns minutos
          e tente novamente.
        </h3>
      </Container>
    </PartnerStandardLayout>
  );
};

export default SystemUnavailable;
