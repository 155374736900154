import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  background: ${COLORS.white};
`;
