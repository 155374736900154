import { pic0, pic1, pic2, pic3 } from 'smiles-react-wallet-core/assets';

export const tutorialMessages = [
  'Crie e administre usuários para cada estabelecimento já cadastrado.',
  'Consulte todas as informações referente a cada estabelecimento.',
  'Busque, visualize e cancele transações.',
  'Gere o QR Code para o cliente efetuar o pagamento.',
  'Caso seu monitor seja pequeno, minimize o menu lateral para uma melhor experiência.',
  'Consulte seus dados, os da empresa e os manuais de utilização do portal quando quiser.',
];

export const pics = [pic0, pic1, pic2, pic3];

export const modalPositions = [
  { top: '60px', left: '380px', right: '' },
  { top: '110px', left: '380px', right: '' },
  { top: '160px', left: '380px', right: '' },
  { top: '120px', left: '380px', right: '' },
  { top: '40px', left: '430px', right: '' },
  { top: '5px', right: '450px', left: '' },
];

export const trianglePositions = [
  { top: '50px', left: '-30px', right: '' },
  { top: '50px', left: '-30px', right: '' },
  { top: '50px', left: '-30px', right: '' },
  { top: '128px', left: '-30px', right: '' },
  { top: '50px', left: '-30px', right: '' },
  { top: '20px', left: '', right: '-28px' },
];
