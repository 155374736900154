import React, { useContext } from 'react';
import { RouteContext } from 'smiles-react-wallet-core/route';
import { FiChevronLeft } from 'react-icons/fi';
import { TutorialContext } from '_context';
import {
  MenuWrapper,
  ToggleButton,
  MenuList,
  MenuItem,
  menuItemActive,
  CustomNavLink,
} from './FakeMenu.styles';

const FakeMenu: React.FC = () => {
  const { tutorialStep } = useContext(TutorialContext)!;
  const { routes } = useContext(RouteContext)!;

  return (
    <MenuWrapper>
      <ToggleButton tutorialStep={tutorialStep}>
        <FiChevronLeft size={30} />
      </ToggleButton>
      <MenuList tutorialStep={tutorialStep}>
        {routes
          .filter(({ showOnMenu }) => showOnMenu)
          .map(({ name, path, menuIcon }: any) => (
            <>
              <MenuItem key={name} className="menuItem">
                <CustomNavLink to={path} activeStyle={menuItemActive}>
                  {menuIcon && menuIcon()}
                  <span className="spanName">{name}</span>
                </CustomNavLink>
              </MenuItem>
            </>
          ))}
      </MenuList>
    </MenuWrapper>
  );
};

export default FakeMenu;
