import React from 'react';
import { useSelector } from 'react-redux';
import { StandardLayout } from 'smiles-react-wallet-core/layout';

import { IStore } from '_redux/store';
import { Container, Message } from './EstablishmentsWelcome.styles';

const EstablishmentsWelcome: React.FC = () => {
  const { loadingEstablishment } = useSelector(
    (state: IStore) => state.establishment,
  );

  return (
    <StandardLayout
      pageTitle="Estabelecimentos"
      isLoading={loadingEstablishment}
    >
      <Container>
        <Message>
          Você ainda não possui nenhum estabelecimento cadastrado.
        </Message>
      </Container>
    </StandardLayout>
  );
};

export default EstablishmentsWelcome;
