import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';
import { Form as FormikForm } from 'formik';

interface LabelProps {
  for?: string;
}

export const Container = styled.div`
  max-width: 474px;
  padding: 30px 20px 10px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    div.inputWrapper {
      margin: 0 15px;
    }
  }
  input {
    font-size: 14px;
    padding: 14.5px 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.tabBorder};
    border-radius: 3px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }
`;

export const SectionTitle = styled.h2`
  display: block;
  white-space: nowrap;
  margin-top: 25px;
  font-family: Nunito;
  font-size: 22px;
  color: ${COLORS.grey6e};
  font-weight: normal;
`;

export const SectionTitleFloating = styled(SectionTitle)`
  float: left;
`;

export const ContainerBorderless = styled.div`
  > div {
    display: block;

    > div {
      margin-top: 10px;
      margin-left: 25px;
    }

    div.inputWrapper {
      margin: 0 15px;
    }
  }
`;

export const Form = styled(FormikForm)`
  h2 {
    margin-top: 25px !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 4px;
    border: 0;
    color: #fff;
    background: ${COLORS.orange};
    height: 56px;
    width: 120px;
    margin-bottom: 22px;
    cursor: pointer;

    &.qrcode {
      width: 168px;
      margin-top: 26px;
    }

    &:disabled {
      background: ${COLORS.lightGrey};
      cursor: default;
    }
  }
`;

export const Label = styled.label<LabelProps>`
  color: ${COLORS.grey6e};
  font-size: 22px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 22px;
`;
