export const PARTNER_USER_CLEAR = 'PARTNER_USER_CLEAR';
export const PARTNER_USER_GET_ALL_START = 'PARTNER_USER_GET_ALL_START';
export const PARTNER_USER_GET_ALL_FINISH = 'PARTNER_USER_GET_ALL_FINISH';
export const PARTNER_USER_GET_BY_ID_START = 'PARTNER_USER_GET_BY_ID_START';
export const PARTNER_USER_GET_BY_ID_FINISH = 'PARTNER_USER_GET_BY_ID_FINISHED';
export const PARTNER_USER_SAVE_OR_UPDATE_START =
  'PARTNER_USER_SAVE_OR_UPDATE_START';
export const PARTNER_USER_SAVE_OR_UPDATE_FINISH =
  'PARTNER_USER_SAVE_OR_UPDATE_FINISHED';
export const PARTNER_USER_CREATE_MASTER_START =
  'PARTNER_USER_CREATE_MASTER_START';
export const PARTNER_USER_CREATE_MASTER_FINISH =
  'PARTNER_USER_CREATE_MASTER_FINISH';
export const PARTNER_USER_DELETE_START = 'PARTNER_USER_DELETE_START';
export const PARTNER_USER_DELETE_FINISH = 'PARTNER_USER_DELETE_FINISH';
export const PARTNER_USER_GET_BY_EMAIL_START =
  'PARTNER_USER_GET_BY_EMAIL_START';
export const PARTNER_USER_GET_BY_EMAIL_FINISH =
  'PARTNER_USER_GET_BY_EMAIL_FINISH';
