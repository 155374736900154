import React from 'react';

import { MdWarning } from 'react-icons/all';

import { Container, Hr } from './styled';
import { PartnerStandardLayout } from '_components';

const OrderInsufficientFunds: React.FC = () => {
  return (
    <PartnerStandardLayout breadcrumbs={[]}>
      <Container>
        <h1>
          <MdWarning />
        </h1>

        <h2>Transação não autorizada</h2>

        <Hr />

        <h3 className="spaced-bottom">
          Cliente não possui milhas suficientes para esse resgate.
        </h3>
      </Container>
    </PartnerStandardLayout>
  );
};

export default OrderInsufficientFunds;
