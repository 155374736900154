import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { IStore } from '_redux/store';
import { getResetPassword } from '_redux/actions';
import CreatePassword from '../screens/CreatePassword';

type UseParamsType = {
  token: string;
};

const CreatePasswordHome: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams<UseParamsType>();

  const { validated, email } = useSelector(
    (state: IStore) => state.resetPassword,
  );

  /** Validate token passed on the URL */
  useEffect(() => {
    dispatch(getResetPassword(token));
  }, [token, dispatch]);

  /** Redirect if the token is invalid */
  useEffect(() => {
    if (validated === false) {
      history.replace('/');
    }
  }, [validated, history]);

  return <CreatePassword />;
};

export default CreatePasswordHome;
