import * as API from 'smiles-react-wallet-core/services/api/partners';
import * as BANK_API from 'smiles-react-wallet-core/services/api/bankData';
import { PartnerType } from 'smiles-react-wallet-core/services/types';
import { Dispatch } from 'redux';
import {
  clearPartnerActionCreator,
  getAllPartnersStartActionCreator,
  getAllPartnersFinishActionCreator,
  getPartnerByIdStartActionCreator,
  getPartnerByIdFinishActionCreator,
  saveOrUpdatePartnerStartActionCreator,
  saveOrUpdatePartnerFinishActionCreator,
  deletePartnerStartActionCreator,
  deletePartnerFinishActionCreator,
} from '../actionCreators';

export const clearPartner = () => async (dispatch: Dispatch) =>
  dispatch(clearPartnerActionCreator());

export const getAllPartners = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getAllPartnersStartActionCreator());

    let { data } = await API.getPartners();
    dispatch(getAllPartnersFinishActionCreator(data));
  } catch (err) {
    dispatch(clearPartnerActionCreator());
  }
};

export const getPartnerById = (
  id: number,
  bankDataPermission?: boolean,
) => async (dispatch: Dispatch) => {
  dispatch(getPartnerByIdStartActionCreator());

  const { data } = await API.getPartnerById(id);
  let bankData;
  if (bankDataPermission) {
    const response = await BANK_API.getPartnerBankData(id);
    bankData = response.data;
  }

  const formattedPartner = {
    ...data,
    ...bankData,
  };

  dispatch(getPartnerByIdFinishActionCreator(formattedPartner));
};

const parsePartner = (partner: PartnerType): PartnerType => ({
  ...partner,
  address: {
    ...partner.address!,
    zipCode: partner.address!.zipCode.replace('-', '')
  }
})

export const saveOrUpdatePartner = (partner: PartnerType) => async (
  dispatch: Dispatch,
) => {
  dispatch(saveOrUpdatePartnerStartActionCreator());

  let req = partner.id
    ? API.updatePartner(parsePartner(partner))
    : API.createPartner(parsePartner(partner));
  const { data } = await req;

  dispatch(saveOrUpdatePartnerFinishActionCreator({ ...partner, id: data.id }));
};

export const deletePartner = (partner: PartnerType) => async (
  dispatch: Dispatch,
) => {
  dispatch(deletePartnerStartActionCreator());

  const { data } = await API.deletePartner(partner);
  dispatch(deletePartnerFinishActionCreator(data));
};
