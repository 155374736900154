import React, { useContext } from 'react';
import FakeHeader from './FakeHeader/FakeHeader';
import FakeMenu from './FakeMenu/FakeMenu';
import TutorialBox from './TutorialBox/TutorialBox';

import { Container, ContainerBody } from './Tutorial.styles';
import { TutorialContext } from '_context';

const Tutorial: React.FC = () => {
  const { isTutorialShowing } = useContext(TutorialContext)!;

  if (!isTutorialShowing) {
    return null;
  }

  return (
    <Container>
      <FakeHeader />
      <ContainerBody>
        <FakeMenu />
      </ContainerBody>
      <TutorialBox />
    </Container>
  );
};

export default Tutorial;
