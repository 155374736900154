import React from 'react';
import Authentication from 'smiles-react-wallet-core/authentication';
import { APPLICATION } from 'smiles-react-wallet-core/config';

const Login: React.FC = () => {
  return (
    <Authentication
      domain={APPLICATION.get('AUTH0_DOMAIN') || ''}
      connection={APPLICATION.get('AUTH0_CONNECTION') || ''}
      client_id={APPLICATION.get('AUTH0_CLIENT_ID') || ''}
      redirect_uri={
        APPLICATION.get('AUTH0_REDIRECT_URI') || window.location.href
      }
    />
  );
};

export default Login;
