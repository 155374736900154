import { ERROR_SHOW, ERROR_CLEAR } from '../actionTypes';

export const showErrorActionCreator = (payload: Error) => ({
  type: ERROR_SHOW,
  payload,
});

export const clearErrorActionCreator = () => ({
  type: ERROR_CLEAR,
});
