import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { getPartnerById } from '_redux/actions';

import { InfoPage } from '../screens';

const Info: React.FC = () => {
  const { user, hasPermission } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPartnerById(user?.partnerId!, hasPermission('read:partnerBank')),
    );
  }, [user, dispatch, hasPermission]);

  return <InfoPage />;
};

export default Info;
