import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 9px 15px 0px ${COLORS.boxShadowGrey};
  padding: 50px;
  margin-bottom: 100px;

  h1 {
    font-size: 25px;
    color: ${COLORS.grey};
  }
`;

export const BottomContainer = styled.div`
  h1 {
    font-size: 20px;
    color: ${COLORS.grey};
  }
`;

export const CancelledTransactionData = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px ${COLORS.boxShadowGrey};
`;

export const OrangeTitle = styled.span`
  color: ${COLORS.orange} !important;
  font-weight: 600;
`;

export const Info = styled.div`
  display: flex;
  padding: 30px;

  &:nth-child(even) {
    background: #f5f5f5;
  }
`;

export const InfoSection = styled(Info)`
  padding: 43px 30px 10px;
  background: #ffffff !important;
`;

export const GreyTitle = styled.span`
  color: ${COLORS.grey};
  width: 330px;
  font-weight: 600 !important;
`;

export const Value = styled.span`
  color: ${COLORS.grey};
  font-size: 14px !important;
  font-weight: 200 !important;
`;
