import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { TransactionsDetails } from '_modules/Transactions/screens';
import { getTransactionById, getExpiringMilesById } from '_redux/actions';
import { IStore } from '_redux/store';

type UseParamsType = {
  id: string;
};

const TransactionsDetailsModule: React.FC = () => {
  const { id } = useParams<UseParamsType>();
  const dispatch = useDispatch();
  const { transactionDetails } = useSelector(
    (state: IStore) => state.transactions,
  );

  useEffect(() => {
    dispatch(getTransactionById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (transactionDetails?.status === 'PROCESSED') {
      dispatch(getExpiringMilesById(id));
    }
  }, [transactionDetails, id, dispatch]);

  return <TransactionsDetails />;
};

export default TransactionsDetailsModule;
