import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Wrapper = styled.div`
  padding: 30px;
  max-width: 555px;
  box-shadow: 0px 9px 15px 0px ${COLORS.boxShadowGrey};
  border-radius: 3px;

  p {
    margin-bottom: 15px;
    color: red;
  }

  .MuiFormGroup-root {
    margin-bottom: 25px;
  }

  legend {
    color: ${COLORS.orange};
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

export const ResendEmailContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  width: fit-content;

  svg {
    color: ${COLORS.orange};
    margin-right: 15px;
  }

  span {
    color: ${COLORS.orange};
    font-weight: 700;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;

  button {
    width: 120px;
  }

  button:nth-of-type(1) {
    margin-right: 5px;
  }
`;
