import {
  TransactionType,
  CheckCancellationType,
  TransactionCancelledType,
} from 'smiles-react-wallet-core/services/types';

import {
  TRANSACTIONS_CLEAR,
  TRANSACTIONS_GET_ALL_START,
  TRANSACTIONS_GET_ALL_FINISH,
  TRANSACTION_GET_BY_ID_START,
  TRANSACTION_GET_BY_ID_FINISH,
  EXPIRING_MILES_GET_BY_ID_START,
  EXPIRING_MILES_GET_BY_ID_FINISH,
  TRANSACTION_CANCEL_START,
  TRANSACTION_CANCEL_FINISH,
} from '../actionTypes';

export const clearTransactionsActionCreator = () => ({
  type: TRANSACTIONS_CLEAR,
});

export const getAllTransactionsStartActionCreator = () => ({
  type: TRANSACTIONS_GET_ALL_START,
});

export const getAllTransactionsFinishActionCreator = (
  transactionsList: TransactionType[],
) => ({
  type: TRANSACTIONS_GET_ALL_FINISH,
  payload: transactionsList,
});

export const getTransactionByIdStartActionCreator = () => ({
  type: TRANSACTION_GET_BY_ID_START,
});

export const getTransactionByIdFinishActionCreator = (
  transaction: TransactionType,
) => ({
  type: TRANSACTION_GET_BY_ID_FINISH,
  payload: transaction,
});

export const getExpiringMilesByTransacionIdStartActionCreator = () => ({
  type: EXPIRING_MILES_GET_BY_ID_START,
});

export const getExpiringMilesByTransacionIdFinishActionCreator = (
  cancellation: CheckCancellationType,
) => ({
  type: EXPIRING_MILES_GET_BY_ID_FINISH,
  payload: cancellation,
});

export const cancelTransacionStartActionCreator = () => ({
  type: TRANSACTION_CANCEL_START,
});

export const cancelTransacionFinishActionCreator = (
  transactionCancelled: TransactionCancelledType,
) => ({
  type: TRANSACTION_CANCEL_FINISH,
  payload: transactionCancelled,
});
