import {
  WIZARD_SET_MAXIMUM_STEP,
  WIZARD_CHANGE_STEP,
  WIZARD_NEXT_STEP,
  WIZARD_PREVIOUS_STEP,
} from '../actionTypes';

const wizardSetMaximumStepReducer = (maxStep) => ({ maxStep });

const wizardChangeStepReducer = (newStep, { currentStep, maxStep }) => ({
  currentStep: newStep >= 0 && newStep < maxStep ? newStep : currentStep,
});

const wizardNextStepReducer = (_, { currentStep, maxStep }) => ({
  currentStep: currentStep < maxStep - 1 ? currentStep + 1 : currentStep,
});

const wizardPreviousStepReducer = (_, { currentStep }) => ({
  currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
});

export const wizardInitialState = {
  currentStep: 0,
  maxStep: 0,
};

export const wizardReducer = {
  [WIZARD_SET_MAXIMUM_STEP]: wizardSetMaximumStepReducer,
  [WIZARD_CHANGE_STEP]: wizardChangeStepReducer,
  [WIZARD_NEXT_STEP]: wizardNextStepReducer,
  [WIZARD_PREVIOUS_STEP]: wizardPreviousStepReducer,
};
