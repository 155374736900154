import React, { useContext } from 'react';
import { IoMdClose } from 'react-icons/io';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import {
  tutorialMessages,
  pics,
  modalPositions,
  trianglePositions,
} from './TutorialInfo';
import { TutorialContext } from '_context';

import {
  Wrapper,
  Container,
  Triangle,
  CloseButton,
  StepsDiv,
  ActionButton,
} from './TutorialBox.styles';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const TutorialBox: React.FC = () => {
  const {
    tutorialStep,
    setTutorialStep,
    isTutorialShowing,
    setIsTutorialShowing,
  } = useContext(TutorialContext)!;

  const { user } = useUser();

  /** Function that closes the tutorial modal */
  function handleCloseButton() {
    localStorage.setItem('smilespartner_tutorial', 'true');
    setTutorialStep(-1);
    setIsTutorialShowing(false);
  }

  /** Function that handles the step selection in the stepper */
  function handleStep(index: number) {
    setTutorialStep(index);
  }

  /** Function that handles the ActionButton click event */
  function handleNext() {
    if (tutorialStep === 5) {
      localStorage.setItem('smilespartner_tutorial', 'true');
      setTutorialStep(-1);
      setIsTutorialShowing(false);
    } else {
      setTutorialStep(tutorialStep + 1);
    }
  }

  return (
    <Wrapper isTutorialShowing={isTutorialShowing}>
      <Container modalPositions={modalPositions[tutorialStep]}>
        <Triangle
          trianglePositions={trianglePositions[tutorialStep]}
          tutorialStep={tutorialStep}
        />
        <CloseButton onClick={handleCloseButton}>
          <IoMdClose size={30} />
        </CloseButton>
        <span>{tutorialMessages[tutorialStep]}</span>
        {tutorialStep > -1 && tutorialStep < 4 && (
          <img src={pics[tutorialStep]} alt="" />
        )}
        {tutorialStep === -1 ? (
          <>
            <span>
              {user?.name}, vamos começar <br /> o tutorial?
            </span>
            <ActionButton type="button" onClick={handleNext}>
              Iniciar
            </ActionButton>
          </>
        ) : (
          <StepsDiv>
            <Stepper nonLinear activeStep={tutorialStep}>
              {[0, 1, 2, 3, 4, 5].map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => handleStep(index)} />
                </Step>
              ))}
            </Stepper>
            <ActionButton type="button" onClick={handleNext}>
              {tutorialStep === 5 ? 'OK!' : 'Próximo'}
            </ActionButton>
          </StepsDiv>
        )}
      </Container>
    </Wrapper>
  );
};

export default TutorialBox;
