import Info from './route-components/Info';
import { Route } from '../../context/types';

export const InfoModule: Route[] = [
  {
    name: 'Dados do parceiro cadastrado',
    path: '/info',

    showOnMenu: false,
    component: Info,
  },
];
