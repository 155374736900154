import React, { useCallback, createRef } from 'react';
import { TextField } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import Recaptcha from 'reaptcha';

import { Button, Divider } from '_components';
import {
  Container,
  DashboardName,
  RecaptchaWrapper,
} from './ForgotPasswordForm.styles';
import { ForgotPasswordFormType } from 'smiles-react-wallet-core/services/types';
import { APPLICATION } from 'smiles-react-wallet-core/config';

const ForgotPasswordForm: React.FC<FormikProps<ForgotPasswordFormType>> = ({
  errors,
  values,
  handleChange,
}) => {
  const recaptchaRef = createRef<any>();
  const handleRecaptcha = useCallback(
    (response: string) => {
      handleChange({
        target: {
          id: 'recaptchaStatus',
          name: 'recaptchaStatus',
          value: response,
        },
      });
    },
    [handleChange],
  );

  return (
    <Container>
      <DashboardName paddingBottom paddingTop smaller>
        Por favor, informe o seu e-mail cadastrado para prosseguirmos com o
        resgate de senha.
      </DashboardName>

      <Form>
        <TextField
          name="email"
          label="Digite o e-mail"
          autoComplete="off"
          spellCheck="false"
          variant="outlined"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
        />

        <div className="divider-width">
          <Divider spacing={30} />
        </div>

        <RecaptchaWrapper highlight={!!errors.recaptchaStatus}>
          <Recaptcha
            ref={recaptchaRef}
            sitekey={APPLICATION.get('AUTH0_CAPTCHA_KEY')}
            onVerify={handleRecaptcha}
          />
        </RecaptchaWrapper>

        <Button
          disabled={
            !!(
              errors.email ||
              errors.recaptchaStatus ||
              !values.email ||
              !values.recaptchaStatus
            )
          }
          type="submit"
          text="Enviar"
        />
      </Form>
    </Container>
  );
};

export default ForgotPasswordForm;
