import { EstablishmentType } from 'smiles-react-wallet-core/services/types';

import {
  ESTABLISHMENT_CLEAR,
  ESTABLISHMENT_GET_ALL_START,
  ESTABLISHMENT_GET_ALL_FINISH,
  ESTABLISHMENT_GET_BY_ID_START,
  ESTABLISHMENT_GET_BY_ID_FINISH,
  ESTABLISHMENT_SAVE_OR_UPDATE_START,
  ESTABLISHMENT_SAVE_OR_UPDATE_FINISH,
  ESTABLISHMENT_DELETE_START,
  ESTABLISHMENT_DELETE_FINISH,
} from '../actionTypes';

export const clearEstablishmentActionCreator = () => ({
  type: ESTABLISHMENT_CLEAR,
});

export const getAllEstablishmentsStartActionCreator = () => ({
  type: ESTABLISHMENT_GET_ALL_START,
});

export const getAllEstablishmentsFinishActionCreator = (
  establishmentList: EstablishmentType[],
) => ({
  type: ESTABLISHMENT_GET_ALL_FINISH,
  payload: establishmentList,
});

export const getEstablishmentByIdStartActionCreator = () => ({
  type: ESTABLISHMENT_GET_BY_ID_START,
});

export const getEstablishmentByIdFinishActionCreator = (
  establishment: EstablishmentType,
) => ({
  type: ESTABLISHMENT_GET_BY_ID_FINISH,
  payload: establishment,
});

export const saveOrUpdateEstablishmentStartActionCreator = () => ({
  type: ESTABLISHMENT_SAVE_OR_UPDATE_START,
});

export const saveOrUpdateEstablishmentFinishActionCreator = (
  establishment: EstablishmentType,
) => ({
  type: ESTABLISHMENT_SAVE_OR_UPDATE_FINISH,
  payload: establishment,
});

export const deleteEstablishmentStartActionCreator = () => ({
  type: ESTABLISHMENT_DELETE_START,
});

export const deleteEstablishmentFinishActionCreator = (
  establishment: EstablishmentType,
) => ({
  type: ESTABLISHMENT_DELETE_FINISH,
  payload: establishment,
});
