import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const TableWrapper = styled.div`
  border-radius: 3px;
  box-shadow: 0px 9px 15px 0px rgba(214, 214, 214, 1);
  margin-top: 40px;

  svg {
    color: ${COLORS.orange};
    cursor: pointer;
  }

  thead {
    border-bottom: 1px solid #e6e6e6;
  }

  tr:nth-child(odd) {
    background: #f5f5f5;
  }

  .MuiTableCell-head {
    width: 250px;
  }

  .MuiTableCell-root {
    border: 0;
    color: ${COLORS.grey};
    font-weight: 400;

    &.MuiTableCell-head {
      font-weight: normal;
      &.textDivisor {
        color: ${COLORS.orange};
        font-weight: normal !important;
      }
    }
  }

  .alignCenter {
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      margin-left: 5px;
    }
  }
`;
