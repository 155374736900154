import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EstablishmentDetails as EstablishmentDetailsPage } from '_modules/Establishments/screens';
import { getEstablishmentById } from '_redux/actions';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

type UseParamsType = {
  id: string;
};

const EstablishmentsDetails: React.FC = () => {
  const { id } = useParams<UseParamsType>();
  const dispatch = useDispatch();
  const { user, hasPermission } = useUser();

  useEffect(() => {
    dispatch(
      getEstablishmentById(
        user?.partnerId!,
        parseInt(id),
        hasPermission('read:establishmentBank'),
      ),
    );
  }, [dispatch, id, user, hasPermission]);

  return <EstablishmentDetailsPage />;
};

export default EstablishmentsDetails;
