import React, { useMemo } from 'react';
import { Form, FormikProps } from 'formik';
import {
  FormControl,
  FormControlLabel,
  TextField,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FaExchangeAlt } from 'react-icons/fa';

import { Divider, Button } from '_components';
import { TransactionCancelType } from 'smiles-react-wallet-core/services/types';
import {
  Container,
  FormWrapper,
  Title,
  Field,
  ButtonContainer,
  AgreementContainer,
} from './styles';
import { IStore } from '_redux/store';

const CancelTransactionForm: React.FC<FormikProps<TransactionCancelType>> = ({
  errors,
  values,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  handleReset,
  handleSubmit,
}) => {
  const { cancellationDetails, transactionDetails } = useSelector(
    (state: IStore) => state.transactions,
  );

  const cancellationNote = values.cancellationNote;
  const commentLenght = useMemo(() => {
    if (cancellationNote === null || cancellationNote.length === 0) {
      return 300;
    } else {
      return 300 - cancellationNote.length;
    }
  }, [cancellationNote]);

  const memoizedExpiringMilesContainer = useMemo(() => {
    if (cancellationDetails && cancellationDetails.expiringMiles > 0) {
      return (
        <AgreementContainer highlight={!!(touched.agreed && errors.agreed)}>
          <FaExchangeAlt size={35} />
          <div className="wrapper">
            <span>
              Estra transação tem{' '}
              <span className="heavy-span">
                {cancellationDetails?.expiringMiles} milhas expiradas
              </span>
              .
            </span>
            <span>
              O cancelamento{' '}
              <span className="orange-heavy-span">
                devolverá{' '}
                {parseInt(transactionDetails?.miles!) -
                  cancellationDetails?.expiringMiles!}{' '}
                milhas
              </span>{' '}
              para o cliente.
            </span>

            <FormControlLabel
              control={
                <Checkbox
                  name="agreed"
                  checked={values.agreed}
                  onChange={handleChange}
                />
              }
              label="Estou ciente"
            />
          </div>
        </AgreementContainer>
      );
    } else {
      setFieldValue('agreed', true);
    }
  }, [
    cancellationDetails,
    errors,
    setFieldValue,
    touched,
    transactionDetails,
    values,
    handleChange,
  ]);

  return (
    <Container>
      <Title>Cancelar Transação</Title>

      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <Field>
            <strong>ID transação</strong>
            <strong className="id-strong">{values.id}</strong>
          </Field>

          <Field>
            <strong>Motivo do Cancelamento</strong>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  name="cancellationReason"
                  select
                  value={values.cancellationReason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ id: 'select' }}
                  variant="outlined"
                  error={
                    !!(touched.cancellationReason && errors.cancellationReason)
                  }
                  helperText={
                    errors.cancellationReason ? errors.cancellationReason : ' '
                  }
                  fullWidth
                >
                  <MenuItem disabled value="Selecione">
                    Selecione
                  </MenuItem>
                  <MenuItem value="Desistência">Desistência</MenuItem>
                  <MenuItem value="Erro de cobrança">Erro de cobrança</MenuItem>
                  <MenuItem value="Concessão">Concessão</MenuItem>
                  <MenuItem value="Motivo Jurídico / Ordem Judicial">
                    Motivo Jurídico / Ordem Judicial
                  </MenuItem>
                </TextField>
              </FormControl>
            </div>
          </Field>

          <Field>
            <strong>Observação</strong>
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="cancellationNote"
                  label="Nome"
                  error={
                    !!(touched.cancellationNote && errors.cancellationNote)
                  }
                  helperText={
                    errors.cancellationNote ? errors.cancellationNote : ' '
                  }
                  autoComplete="off"
                  spellCheck="false"
                  variant="outlined"
                  value={values.cancellationNote}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 300 }}
                  multiline
                  rows={8}
                  fullWidth
                />
              </FormControl>
              <span className="floating-chars">{commentLenght} caracteres</span>
            </div>
          </Field>

          {memoizedExpiringMilesContainer}

          <Divider color="#FF5A00" spacing={50} />

          <ButtonContainer>
            <Button text="Cancelar" reverse onClick={handleReset} />
            <Button text="Confirmar" type="submit" />
          </ButtonContainer>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default CancelTransactionForm;
