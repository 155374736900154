import React from 'react';
import { PartnerStandardLayout } from '_components';
import { WelcomeWrapper, Welcome, OrangeBold, Description } from './styled';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

const DashboardWelcome: React.FC = () => {
  const { user } = useUser();

  return (
    <PartnerStandardLayout>
      {user?.permissions?.length && user?.permissions?.length > 3 ? (
        <WelcomeWrapper>
          <Welcome>
            Bem vindo ao Portal
            <br />
            <OrangeBold>Resgate </OrangeBold>instantâneo.
          </Welcome>
          <Description>
            Aqui você poderá ter todo acesso as informações dos
            <br />
            seus usuários, estabelecimentos, vendas e acompanhamento das suas
            transações.
          </Description>
        </WelcomeWrapper>
      ) : (
        <WelcomeWrapper>
          <Welcome>Você não possui permissão</Welcome>
          <Description>
            Entre em contato com o suporte caso seja um erro.
          </Description>
        </WelcomeWrapper>
      )}
    </PartnerStandardLayout>
  );
};

export default DashboardWelcome;
