import { PartnerUserType } from 'smiles-react-wallet-core/services/types';
import { IPartnerUserState } from '../types';

import {
  PARTNER_USER_CLEAR,
  PARTNER_USER_GET_ALL_START,
  PARTNER_USER_GET_ALL_FINISH,
  PARTNER_USER_GET_BY_ID_START,
  PARTNER_USER_GET_BY_ID_FINISH,
  PARTNER_USER_SAVE_OR_UPDATE_START,
  PARTNER_USER_SAVE_OR_UPDATE_FINISH,
  PARTNER_USER_CREATE_MASTER_FINISH,
  PARTNER_USER_CREATE_MASTER_START,
  PARTNER_USER_DELETE_START,
  PARTNER_USER_DELETE_FINISH,
  PARTNER_USER_GET_BY_EMAIL_START,
  PARTNER_USER_GET_BY_EMAIL_FINISH,
  ERROR_SHOW,
} from '../actionTypes';

const clearPartnerUserReducer = () => ({ ...partnerUserInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingPartnerUser: false,
  savingPartnerUser: false,
});

const getAllStartReducer = () => ({
  loadingPartnerUser: true,
  partnerUserList: [],
});
const getAllFinishReducer = (partnerUserList: Array<PartnerUserType>) => ({
  loadingPartnerUser: false,
  partnerUserList,
});

const getByIdStartReducer = () => ({
  loadingPartnerUser: true,
  partnerUserDetails: undefined,
});
const getByIdFinishReducer = (partnerUserDetails: PartnerUserType) => ({
  loadingPartnerUser: false,
  partnerUserDetails,
});

const getByEmailStartReducer = () => ({
  loadingPartnerUser: true,
  partnerUserDetails: undefined,
});
const getByEmailFinishReducer = (partnerUserDetails: PartnerUserType) => ({
  loadingPartnerUser: false,
  partnerUserDetails,
});

const saveOrUpdateStartReducer = () => ({ savingPartnerUser: true });
const saveOrUpdateFinishReducer = (partnerUserDetails: PartnerUserType) => ({
  savingPartnerUser: false,
  partnerUserDetails,
});

const createMasterStartReducer = () => ({ savingPartnerUser: true });
const createMasterFinishReducer = (
  partnerUserDetails: PartnerUserType,
  { partnerUserList }: IPartnerUserState,
) => ({
  savingPartnerUser: false,
  partnerUserDetails,
  partnerUserList: [...partnerUserList, partnerUserDetails],
});

const deleteStartReducer = () => ({ savingPartnerUser: true });
const deleteFinishReducer = (partnerUserDetails: PartnerUserType) => ({
  savingPartnerUser: false,
  partnerUserDetails,
});

export const partnerUserInitialState: IPartnerUserState = {
  loadingPartnerUser: false,
  savingPartnerUser: false,
  partnerUserDetails: undefined,
  partnerUserList: [],
};

export const partnerUserReducer = {
  [ERROR_SHOW]: clearLoading,
  [PARTNER_USER_CLEAR]: clearPartnerUserReducer,
  [PARTNER_USER_GET_ALL_START]: getAllStartReducer,
  [PARTNER_USER_GET_ALL_FINISH]: getAllFinishReducer,
  [PARTNER_USER_GET_BY_ID_START]: getByIdStartReducer,
  [PARTNER_USER_GET_BY_ID_FINISH]: getByIdFinishReducer,
  [PARTNER_USER_GET_BY_EMAIL_START]: getByEmailStartReducer,
  [PARTNER_USER_GET_BY_EMAIL_FINISH]: getByEmailFinishReducer,
  [PARTNER_USER_SAVE_OR_UPDATE_START]: saveOrUpdateStartReducer,
  [PARTNER_USER_SAVE_OR_UPDATE_FINISH]: saveOrUpdateFinishReducer,
  [PARTNER_USER_CREATE_MASTER_START]: createMasterStartReducer,
  [PARTNER_USER_CREATE_MASTER_FINISH]: createMasterFinishReducer,
  [PARTNER_USER_DELETE_START]: deleteStartReducer,
  [PARTNER_USER_DELETE_FINISH]: deleteFinishReducer,
};
