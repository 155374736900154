import { Dispatch } from 'redux';

import * as API from 'smiles-react-wallet-core/services/api/localusers';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import {
  clearLocalUsersActionCreator,
  getAllLocalUsersFinishActionCreator,
  getAllLocalUsersStartActionCreator,
  getLocalUserByIdFinishActionCreator,
  getLocalUserByIdStartActionCreator,
  saveOrUpdateLocalUserFinishActionCreator,
  saveOrUpdateLocalUserStartActionCreator,
} from '../actionCreators';

/** CLEAR LOCALUSERS */
export const clearLocalUsers = () => async (dispatch: Dispatch) =>
  dispatch(clearLocalUsersActionCreator());

/** GET ALL LOCALUSERS */
export const getAllLocalUsers = (partnerId?: number, order?: string) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(getAllLocalUsersStartActionCreator());

    const { data } = await API.getLocalUsers(partnerId, order);
    dispatch(getAllLocalUsersFinishActionCreator(data));
  } catch (err) {
    dispatch(clearLocalUsersActionCreator());
  }
};

/** GET LOCALUSER BY ID */
export const getLocalUserById = (partnerId: number, userId: number) => async (
  dispatch: Dispatch,
) => {
  dispatch(getLocalUserByIdStartActionCreator());

  const { data } = await API.getLocalUserById(partnerId, userId);
  dispatch(getLocalUserByIdFinishActionCreator(data));
};

/** PATCH LOCALUSER */
export const saveOrUpdateLocalUser = (
  navigateTo: any,
  localUser: LocalUserType,
  partnerId: number,
) => async (dispatch: Dispatch) => {
  dispatch(saveOrUpdateLocalUserStartActionCreator());
  const updatePath = 'Atualização de usuário finalizada';
  const registerPath = 'Criação de usuário finalizada';

  try {
    const request = localUser.id
      ? API.updateLocalUser(localUser, partnerId)
      : API.createLocalUser(localUser, partnerId);
    const { data } = await request;

    const formattedData = {
      ...data,
      establishment: localUser.establishment,
    };

    dispatch(
      saveOrUpdateLocalUserFinishActionCreator(
        localUser.id ? data : formattedData,
      ),
    );

    navigateTo(`${localUser.id ? updatePath : registerPath}`, {
      status: 'sucesso',
    });
  } catch (err) {
    if (err?.response?.data?.message && err?.response?.data?.statusCode) {
      throw err;
    } else {
      navigateTo(`${localUser.id ? updatePath : registerPath}`, {
        status: 'falha',
      });
    }
  }
};
