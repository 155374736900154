import React, { useEffect, useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { useRoute } from 'smiles-react-wallet-core/route';
import { LocalUserType } from 'smiles-react-wallet-core/services/types';
import { Button } from '_components';
import { Container, ProfileFilterWrapper } from './LocalUsersFilter.styles';
import { accessProfiles } from 'smiles-react-wallet-core/utils';

type LocalUsersFilterProps = {
  localUsers: LocalUserType[];
  setFilteredLocalUsers: (value: any) => void;
};

const LocalUsersFilter: React.FC<LocalUsersFilterProps> = ({
  localUsers,
  setFilteredLocalUsers,
}) => {
  const { hasPermission } = useUser();
  const { navigateTo } = useRoute();
  const [nameFilter, setNameFilter] = useState<string>('');
  const [accessProfileFilter, setAccessProfileFilter] = useState<string>(' ');

  function handleInputChange(event: any) {
    setNameFilter(event.target.value.toLowerCase());
  }

  function handleSelectChange(event: any) {
    const { value } = event.target;
    setAccessProfileFilter(value);
  }

  function handleAddLocalUser() {
    navigateTo('Criar Usuário');
  }

  useEffect(() => {
    /** Filters */
    const filterByName = (localUser: LocalUserType) =>
      nameFilter !== ''
        ? (localUser.firstName! + localUser.lastName!)
            .toLowerCase()
            .indexOf(nameFilter) >= 0
        : true;

    const filterByProfile = (localUser: LocalUserType) =>
      accessProfileFilter !== ' '
        ? localUser.accessProfile === accessProfileFilter
        : true;
    /** --- */

    setFilteredLocalUsers(
      localUsers.filter(filterByName).filter(filterByProfile),
    );
  }, [accessProfileFilter, nameFilter, localUsers, setFilteredLocalUsers]);

  useEffect(() => {
    /** Reset fields when user set column ordering */
    setNameFilter('');
    setAccessProfileFilter(' ');
  }, [localUsers]);

  return (
    <Container>
      <div className="space-between">
        <div className="name-wrapper">
          <FormControl>
            <TextField
              id="name"
              value={nameFilter}
              label="Pesquisar usuário local"
              onChange={handleInputChange}
              variant="outlined"
            />
          </FormControl>
        </div>

        <ProfileFilterWrapper>
          <InputLabel htmlFor="accessProfile">Filtrar:</InputLabel>

          <FormControl variant="outlined">
            <Select
              id="accessProfile"
              onChange={handleSelectChange}
              value={accessProfileFilter}
            >
              <MenuItem value=" ">
                <span className="menuItem-accessProfile">Perfil de Acesso</span>
              </MenuItem>
              {accessProfiles.map((profile) => (
                <MenuItem key={profile.value} value={profile.value}>
                  {profile.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ProfileFilterWrapper>
      </div>
      {hasPermission('create:partnerUser') && (
        <Button
          text="Criar Usuário"
          onClick={handleAddLocalUser}
          reverse
          customStyle={{ marginLeft: 24 }}
        />
      )}
    </Container>
  );
};

export default LocalUsersFilter;
