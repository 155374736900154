import React, { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import {
  TransactionFilterSchema,
  initialFilter,
} from 'smiles-react-wallet-core/utils/models';
import {
  TransactionType,
  FilterType,
} from 'smiles-react-wallet-core/services/types';
import { useRoute } from 'smiles-react-wallet-core/route';
import { formatStatus, utcDateTime } from 'smiles-react-wallet-core/utils';

import { IStore } from '_redux/store';
import { getAllTransactions } from '_redux/actions';
import {
  EnhancedListing,
  Pagination,
  PartnerTransactionsFilter,
  Divider,
  PartnerStandardLayout,
} from '_components';

import { Container, BottomContainer } from './TransactionsList.styles';
import {
  customHeaders,
  initialOrderBy,
  itemsPerPage,
  useMemoPagedList,
} from './TransactionsListData';

interface TransactionsListProps {
  partnerId?: string;
}

const TransactionsList: React.FC<TransactionsListProps> = ({ partnerId }) => {
  const { navigateTo } = useRoute();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState('');
  const [filter, setFilter] = useState(initialFilter);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const { transactionsList, loadingTransactions } = useSelector(
    (state: IStore) => state.transactions,
  );

  function handleTransactionDetails(id: string) {
    navigateTo('Detalhes Transação', { id });
  }

  function handleTitle(listLength?: number) {
    if (listLength === undefined) {
      setTitle(`Transações recentes`);
    } else if (listLength === 1) {
      setTitle(`1 resultado encontrado`);
    } else if (listLength > 1) {
      setTitle(`${listLength} resultados encontrados`);
    } else {
      setTitle('Nenhum resultado encontrado');
    }
  }

  /** handleSubmit from TransactionsFilter form */
  const handleSubmit = (values: FilterType) => {
    setPage(1);
    setFilter({
      ...values,
      partnerId: partnerId,
      status: values.status?.length === 0 ? undefined : values.status,
    });
    dispatch(
      getAllTransactions(
        {
          ...values,
          partnerId: partnerId,
          status: values.status?.length === 0 ? undefined : values.status,
        },
        handleTitle,
      ),
    );
  };

  useEffect(() => {
    dispatch(
      getAllTransactions(
        {
          order: 'DATE,DESC',
          initDate: null,
          endDate: null,
          partnerId,
        },
        (length) => length && handleTitle(),
      ),
    );
  }, [dispatch, partnerId]);

  /** dispatch getAllTransactions when user changes orderBy */
  useEffect(() => {
    if (orderBy !== initialOrderBy) {
      setPage(1);
      dispatch(
        getAllTransactions(
          {
            ...filter,
            partnerId: partnerId,
            order: orderBy.column.toUpperCase().concat(',', orderBy.order),
          },
          handleTitle,
        ),
      );
    }
  }, [orderBy, setOrderBy, dispatch, setPage, filter, partnerId]);

  /** Paged transactionsList using useMemo */
  const memoizedPagedTransactionsList = useMemoPagedList(
    page,
    itemsPerPage,
    transactionsList,
  );

  const memoizeFilters = useMemo(() => PartnerTransactionsFilter, []);

  return (
    <PartnerStandardLayout
      breadcrumbs={[]}
      pageTitle="Transações"
      isLoading={loadingTransactions}
    >
      <Container>
        <Formik
          initialValues={initialFilter}
          validationSchema={TransactionFilterSchema}
          onSubmit={handleSubmit}
          component={memoizeFilters}
        />
        <h1>{title}</h1>

        {transactionsList.length > 0 && (
          <>
            <EnhancedListing
              list={memoizedPagedTransactionsList}
              headers={{
                name: 'Nome',
                transactionid: 'Transação (ID)',
                memberNumber: 'Número Smiles',
                date: 'Data/ hora',
                status: 'Status',
              }}
              fieldParser={{
                transactionid: ({ id }) => `${id}`,
                name: ({ clientName }) => `${clientName}`,
                date: ({ createDate }) =>
                  `${utcDateTime(createDate)}`,
                status: ({ status }) => {
                  return formatStatus(status);
                },
              }}
              onClickDetails={({ id }: TransactionType) =>
                handleTransactionDetails(id!)
              }
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              hasPermission
            />

            <Divider color="#FF5A00" spacing={50} />

            <BottomContainer>
              <div className="customButton">
                <CSVLink
                  data={transactionsList}
                  headers={customHeaders}
                  filename={`${moment().format(
                    'DD_MM_YYYY',
                  )}_TRANSACTIONS_EXPORT.csv`}
                >
                  Salvar extrato
                </CSVLink>
              </div>

              <Pagination
                itShouldHide={
                  transactionsList.length < itemsPerPage ? true : false
                }
                itemsPerPage={itemsPerPage}
                page={page}
                setPage={setPage}
                listLength={transactionsList.length}
              />
            </BottomContainer>
          </>
        )}
      </Container>
    </PartnerStandardLayout>
  );
};

export default TransactionsList;
