import { MdMonetizationOn } from 'react-icons/md';

import OrdersHome from './route-components/OrdersHome';
import OrderFinished from './route-components/OrderFinished';
import {
  OrderUnavailable,
  OrderNotAuthorized,
  OrderInsufficientFunds,
} from './route-components/OrderError';
import { Route } from '_context/types';

export const Orders: Route[] = [
  {
    name: 'Venda',
    path: '/venda',
    showOnMenu: true,
    component: OrdersHome,
    menuIcon: MdMonetizationOn,
    permissions: ['create:qrcode'],
  },
  {
    name: 'Venda concluída',
    path: '/venda/:orderId/concluida',
    showOnMenu: false,
    component: OrderFinished,
  },
  {
    name: 'Venda não disponível',
    path: '/venda/nao-disponivel',
    showOnMenu: false,
    component: OrderUnavailable,
  },
  {
    name: 'Venda não autorizada',
    path: '/venda/nao-autorizada',
    showOnMenu: false,
    component: OrderNotAuthorized,
  },
  {
    name: 'Venda sem saldo',
    path: '/venda/saldo-insuficiente',
    showOnMenu: false,
    component: OrderInsufficientFunds,
  },
];
