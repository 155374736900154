import React from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { formatAccessProfile } from 'smiles-react-wallet-core/utils';
import { useRoute } from 'smiles-react-wallet-core/route';

import { Divider, Button, PartnerStandardLayout } from '_components';

import {
  Container,
  ConfirmationCard,
  Title,
  MiddleContainer,
  UserContainer,
  UserHeader,
  Info,
  BottomContainer,
} from './LocalUsersFinish.styles';
import { IStore } from '_redux/store';

type LocalUsersFinishProps = {
  status: string;
  origin: string;
  title: string;
};

const LocalUsersFinish: React.FC<LocalUsersFinishProps> = ({
  status,
  origin,
  title,
}) => {
  const { navigateTo } = useRoute();
  const { localUser, loadingLocalUsers } = useSelector(
    (state: IStore) => state.localUsers,
  );

  function handleGoBackToUsers() {
    navigateTo('Usuários Locais');
  }

  function goToUserDetails() {
    navigateTo('Editar Usuário', { id: localUser?.id });
  }

  return (
    <PartnerStandardLayout isLoading={loadingLocalUsers}>
      <Container>
        <ConfirmationCard>
          <Title>{title}</Title>

          {origin === 'registrar' && (
            <>
              <span>
                Os dados para o login do usuário foram enviadas para o e-mail:
              </span>
              <span>{localUser?.email}</span>
            </>
          )}
          <div className="divider-limiter">
            <Divider color="#e6e6e6" />
          </div>

          <Button
            text="Ir para Usuários"
            reverse
            onClick={handleGoBackToUsers}
          />
        </ConfirmationCard>

        {status === 'sucesso' && (
          <>
            <Divider />

            <MiddleContainer>
              <Title>Dados do Usuário</Title>

              <UserContainer>
                <UserHeader>
                  <span>
                    {localUser?.firstName?.concat(' ', localUser?.lastName!)}
                  </span>
                  <MdEdit size={35} onClick={goToUserDetails} />
                </UserHeader>

                <Info>
                  <span>E-mail</span>
                  <span>{localUser?.email}</span>
                </Info>

                <Info>
                  <span>Perfil de Acesso</span>
                  <span>{formatAccessProfile(localUser?.accessProfile)}</span>
                </Info>

                <Info>
                  <span>Estabelecimento</span>
                  <span>{localUser?.establishment?.name}</span>
                </Info>

                <Info>
                  <span>Telefone</span>
                  <span>{localUser?.phone}</span>
                </Info>

                <Info>
                  <span>Status</span>
                  <span>
                    {localUser?.isActive! === 1 ? 'Ativo' : 'Inativo'}
                  </span>
                </Info>
              </UserContainer>
            </MiddleContainer>

            <Divider />

            <BottomContainer>
              <Button
                text="Voltar para usuários"
                reverse
                onClick={handleGoBackToUsers}
              />
            </BottomContainer>
          </>
        )}
      </Container>
    </PartnerStandardLayout>
  );
};

export default LocalUsersFinish;
