import React, { useCallback } from 'react';

import { MdWarning } from 'react-icons/all';

import { Button, PartnerStandardLayout } from '_components';
import { ActionFooter, Container, Hr } from './styled';

const OrderUnavailable: React.FC = () => {
  const retry = useCallback(() => {
    window.history.go(-1);
  }, []);

  return (
    <PartnerStandardLayout breadcrumbs={[]}>
      <Container>
        <h1>
          <MdWarning />
        </h1>

        <h2>Sistema indisponível</h2>

        <Hr />

        <h3>Tente novamente em instantes.</h3>

        <ActionFooter>
          <Button reverse text="Tentar novamente." onClick={retry} />
        </ActionFooter>
      </Container>
    </PartnerStandardLayout>
  );
};

export default OrderUnavailable;
