import React from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from 'smiles-react-wallet-core/route';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

import { Button, PartnerStandardLayout, Divider } from '_components';
import { IStore } from '_redux/store';
import { Container, Message } from './LocalUsersWelcome.styles';

const LocalUsersWelcome: React.FC = () => {
  const { navigateTo } = useRoute();
  const { hasPermission } = useUser();

  const { loadingLocalUsers } = useSelector(
    (state: IStore) => state.localUsers,
  );

  function handleAddLocalUser() {
    navigateTo('Criar Usuário');
  }

  return (
    <PartnerStandardLayout
      pageTitle="Usuários Locais"
      isLoading={loadingLocalUsers}
    >
      <Container>
        <Message>
          Você ainda não possui um usuário Local
          {hasPermission('create:partnerUser') && ', deseja criar o primeiro?'}
          <Divider spacing={40} />
        </Message>
        {hasPermission('create:partnerUser') && (
          <Button text="Criar" onClick={handleAddLocalUser} />
        )}
      </Container>
    </PartnerStandardLayout>
  );
};

export default LocalUsersWelcome;
