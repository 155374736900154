import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DashboardWelcome } from '_pages';

interface PartnerHomeProps {}

export const DashboardHome: React.FC<
  PartnerHomeProps & RouteComponentProps
> = () => {
  return <DashboardWelcome />;
};
