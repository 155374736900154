import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';
import {
  IApplicationState,
  IEstablishmentState,
  ILocalUsersState,
  IPartnerUserState,
  IPartnerState,
  ITransactionsState,
  IResetPasswordState,
} from './types';
import { showErrorActionCreator } from '_redux/actionCreators/applicationActionCreators';

export interface IStore {
  application: IApplicationState;
  establishment: IEstablishmentState;
  partner: IPartnerState;
  localUsers: ILocalUsersState;
  partnerUser: IPartnerUserState;
  transactions: ITransactionsState;
  resetPassword: IResetPasswordState;
}

const handleErrors = ({ dispatch, getState }: any) => (next: any) => (
  action: any,
) => {
  if (typeof action === 'function') {
    return action(dispatch, getState).catch((error: any) => {
      if (error.response) {
        dispatch(showErrorActionCreator(error.response.data));
        console.error(error);
      }
      return {};
    });
  }

  return next(action);
};
const store = createStore(reducers, applyMiddleware(handleErrors, thunk));

export default store;
