import React from 'react';
import moment from 'moment';

import { formatStatus, utcDateTime } from 'smiles-react-wallet-core/utils';
import { TransactionType } from 'smiles-react-wallet-core/services/types';

import {
  TransactionWrapper,
  Info,
  Label,
  Value,
} from './TransactionContainer.styles';

type TransactionContainerProps = {
  transaction: TransactionType;
};

const TransactionContainer: React.FC<TransactionContainerProps> = ({
  transaction,
}) => {
  return (
    <TransactionWrapper>
      <Info>
        <Label>Nome</Label>
        <Value>{transaction?.clientName}</Value>
      </Info>

      <Info>
        <Label>E-mail</Label>
        <Value>{transaction?.clientEmail}</Value>
      </Info>

      <Info>
        <Label>Nome do Produto</Label>
        <Value>{transaction?.productType}</Value>
      </Info>

      <Info>
        <Label>Valor em Milhas</Label>
        <Value>{transaction?.miles}</Value>
      </Info>

      <Info>
        <Label>Preço (R$)</Label>
        <Value>{transaction?.cost}</Value>
      </Info>

      <Info>
        <Label>Data/hora da Transação</Label>
        <Value>
          {utcDateTime(transaction?.createDate)}
        </Value>
      </Info>

      <Info>
        <Label>Data/hora do Processamento</Label>
        <Value>
          {transaction?.processedDate
            ? utcDateTime(transaction?.processedDate)
            : ''}
        </Value>
      </Info>

      <Info>
        <Label>Número do Documento</Label>
        <Value></Value>
      </Info>

      <Info>
        <Label>Número do Membro</Label>
        <Value>{transaction?.memberNumber}</Value>
      </Info>

      <Info>
        <Label>Nome do Parceiro</Label>
        <Value>{transaction?.partner?.name}</Value>
      </Info>

      <Info>
        <Label>ID do Parceiro</Label>
        <Value>{transaction?.partnerId}</Value>
      </Info>

      <Info>
        <Label>ID Transação</Label>
        <Value>{transaction?.id}</Value>
      </Info>

      <Info>
        <Label>E-mail do Operador</Label>
        <Value>{transaction?.operator?.email}</Value>
      </Info>

      <Info>
        <Label>Status da Transação</Label>
        <Value>{formatStatus(transaction?.status)}</Value>
      </Info>
    </TransactionWrapper>
  );
};

export default TransactionContainer;
