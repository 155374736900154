import { IApplicationState } from '../types';

import { ERROR_SHOW, ERROR_CLEAR } from '../actionTypes';

export const applicationInitialState: IApplicationState = {
  error: null,
};

const errorClearReducer = () => ({ ...applicationInitialState });

const showErrorReducer = (error: any) => {
  return {
    error: { ...error },
  };
};

export const applicationReducer = {
  [ERROR_SHOW]: showErrorReducer,
  [ERROR_CLEAR]: errorClearReducer,
};
