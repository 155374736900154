import React, { useEffect, useContext, Fragment } from 'react';
import { StandardLayout } from 'smiles-react-wallet-core/layout';
import { PartnerProfileTip, Tutorial } from '_components';
import { TutorialContext } from '_context';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { ErrorModal } from 'smiles-react-wallet-core/components';
import useError from '_redux/helpers/useError';
import { DASHBOARD_CONFIG } from 'smiles-react-wallet-core/config';

const PartnerStandardLayout: React.FC<any> = ({ children, ...props }) => {
  const { user } = useUser();
  const { setIsTutorialShowing } = useContext(TutorialContext)!;
  const { error, onClose } = useError();

  useEffect(() => {
    if (user && !localStorage.getItem('smilespartner_tutorial')) {
      setIsTutorialShowing(true);
    }
  }, [user, setIsTutorialShowing]);

  return (
    <Fragment>
      <ErrorModal error={error} onClose={onClose} />
      <StandardLayout
        appTitle={DASHBOARD_CONFIG.partnerTitle}
        profile={<PartnerProfileTip />}
        {...props}
      >
        {children}
        <Tutorial />
      </StandardLayout>
    </Fragment>
  );
};

export default PartnerStandardLayout;
