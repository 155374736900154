import styled from 'styled-components';
import { THEME } from 'smiles-react-wallet-core/config';

export const WelcomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
`;

export const Welcome = styled.div`
  padding-top: 100px;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  color: ${THEME.fontColor};
`;

export const OrangeBold = styled.span`
  font-weight: bold;
  color: ${THEME.primaryColor};
`;

export const Description = styled.div`
  padding: 30px 0 40px 0;
  font-size: 16px;
  line-height: 21px;
  color: ${THEME.fontColor};
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
`;

export const Divider = styled.div`
  margin: 0 auto;
  width: 430px;
  border-bottom: 1px solid #f2f2f2;
`;
