import { Dispatch } from 'redux';

import { url } from 'smiles-react-wallet-core/utils';
import * as API from 'smiles-react-wallet-core/services/api/resetPassword';
import { ChangePasswordType } from 'smiles-react-wallet-core/services/types';

import {
  clearResetPasswordActionCreator,
  resetPasswordPostStartActionCreator,
  resetPasswordPostFinishActionCreator,
  resetPasswordGetStartActionCreator,
  resetPasswordGetFinishActionCreator,
  changePasswordFinishActionCreator,
  changePasswordStartActionCreator,
} from '../actionCreators';

/** CLEAR RESETPASSWORD */
export const clearResetPassword = () => async (dispatch: Dispatch) =>
  dispatch(clearResetPasswordActionCreator());

/** POST RESETPASSWORD */
export const postResetPassword = (
    email: string,
    reCAPTCHAToken: string,
  ) => async (
  dispatch: Dispatch,
) => {
  dispatch(resetPasswordPostStartActionCreator());
  const { data } = await API.resetPasswordPost(email, url(), reCAPTCHAToken);
  dispatch(resetPasswordPostFinishActionCreator(data));
};

/** GET RESETPASSWORD */
export const getResetPassword = (token: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(resetPasswordGetStartActionCreator());

  const { data } = await API.resetPasswordGet(token);

  const formattedData = {
    ...data,
    token,
  };

  dispatch(resetPasswordGetFinishActionCreator(formattedData));
};

/** Change Password */
export const changePassword =
  (
    history: any,
    changePassword: ChangePasswordType,
    reCAPTCHAToken: string,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(changePasswordStartActionCreator());

    const { data } = await API.changePassword(changePassword, reCAPTCHAToken);

    dispatch(changePasswordFinishActionCreator(data));

    history.push('/senha/reenvio');
  };
