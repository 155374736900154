import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  position: relative;
  margin-bottom: -2px;
  width: fit-content;
  border-bottom: 3px solid ${COLORS.orange};
  padding: 0 10px;
  font-size: 20px;
  color: ${COLORS.grey};
`;

export const PartnerContainer = styled.div`
  margin-top: 20px;
  background: #f5f8f5;

  span {
    color: ${COLORS.grey};
  }
`;

export const PartnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #efefef;

  span {
    color: ${COLORS.grey};
    font-size: 20px;
    font-weight: 700;
  }

  svg {
    color: ${COLORS.orange};
  }
`;

export const PartnerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  .withMarginBottom {
    margin-bottom: 25px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoName = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const InfoValue = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
    margin-top: 5px;
    color: ${COLORS.grey};
  }
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
`;
