import { IResetPasswordState } from '../types';
import { ResetPasswordGet } from 'smiles-react-wallet-core/services/types';

import {
  RESETPASSWORD_CLEAR,
  RESETPASSWORD_POST_START,
  RESETPASSWORD_POST_FINISH,
  RESETPASSWORD_GET_START,
  RESETPASSWORD_GET_FINISH,
  CHANGEPASSWORD_START,
  CHANGEPASSWORD_FINISH,
  ERROR_CLEAR,
} from '../actionTypes';

const clearResetPasswordReducer = () => ({ ...resetPasswordInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingResetPassword: false,
});

const resetPostStartReducer = () => ({
  loadingResetPassword: true,
});

const resetPostFinishReducer = (token: string) => ({
  loadingResetPassword: false,
  token,
});

const resetGetStartReducer = () => ({
  loadingResetPassword: true,
});

const resetGetFinishReducer = (resetData: ResetPasswordGet) => ({
  loadingResetPassword: false,
  validated: resetData.validated,
  email: resetData.email,
  token: resetData.token,
});

const changePasswordStartReducer = () => ({
  loadingResetPassword: true,
});

const changePasswordFinishReducer = (updated: boolean) => ({
  loadingResetPassword: false,
  updated,
});

export const resetPasswordInitialState: IResetPasswordState = {
  loadingResetPassword: false,
  token: null,
  validated: null,
  email: null,
  updated: null,
};

export const resetPasswordReducer = {
  [ERROR_CLEAR]: clearLoading,
  [RESETPASSWORD_CLEAR]: clearResetPasswordReducer,
  [RESETPASSWORD_POST_START]: resetPostStartReducer,
  [RESETPASSWORD_POST_FINISH]: resetPostFinishReducer,
  [RESETPASSWORD_GET_START]: resetGetStartReducer,
  [RESETPASSWORD_GET_FINISH]: resetGetFinishReducer,
  [CHANGEPASSWORD_START]: changePasswordStartReducer,
  [CHANGEPASSWORD_FINISH]: changePasswordFinishReducer,
};
