import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  width: 100%;
  margin: 64px auto;
  border-radius: 3px;
  background-color: ${COLORS.white};
  box-shadow: 0 5px 10px 0 rgba(138, 149, 158, 0.2);
  overflow: auto;

  h1 {
    color: ${COLORS.grey};
    margin: 25px auto 8px;
    text-align: center;

    svg {
      font-size: 52px;
      path {
        fill: ${COLORS.grey};
      }
    }
  }

  h2 {
    color: ${COLORS.grey};
    font-family: Nunito;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 32px;
  }

  h3 {
    color: ${COLORS.grey};
    font-family: Nunito;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin: 25px auto;

    &.spaced-bottom {
      margin-bottom: 88px;
    }
  }
`;

export const ActionFooter = styled.div`
  width: 100%;
  max-width: 249px;
  text-align: center;
  margin: 25px auto 30px;
`;

export const Hr = styled.hr`
  height: 1px;
  width: 379px;
  margin: 0 auto;
  background-color: ${COLORS.whitef2};
`;
