import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  height: 260px;
  width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  background-color: ${COLORS.white};
  box-shadow: 0 5px 10px 0 rgba(138, 149, 158, 0.2);
  overflow: auto;

  h2 {
    color: ${COLORS.grey6e};
    font-family: Nunito;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin-top: 61px;
    margin-bottom: 32px;
  }
`;

export const ActionFooter = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.whitef2};
  padding-top: 38px;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
`;
