import { PartnerType } from 'smiles-react-wallet-core/services/types';

import {
  PARTNER_CLEAR,
  PARTNER_GET_ALL_START,
  PARTNER_GET_ALL_FINISH,
  PARTNER_GET_BY_ID_START,
  PARTNER_GET_BY_ID_FINISH,
  PARTNER_SAVE_OR_UPDATE_START,
  PARTNER_SAVE_OR_UPDATE_FINISH,
  PARTNER_DELETE_START,
  PARTNER_DELETE_FINISH,
} from '../actionTypes';

export const clearPartnerActionCreator = () => ({
  type: PARTNER_CLEAR,
});

export const getAllPartnersStartActionCreator = () => ({
  type: PARTNER_GET_ALL_START,
});

export const getAllPartnersFinishActionCreator = (
  partnerList: PartnerType[],
) => ({
  type: PARTNER_GET_ALL_FINISH,
  payload: partnerList,
});

export const getPartnerByIdStartActionCreator = () => ({
  type: PARTNER_GET_BY_ID_START,
});

export const getPartnerByIdFinishActionCreator = (partner: PartnerType) => ({
  type: PARTNER_GET_BY_ID_FINISH,
  payload: partner,
});

export const saveOrUpdatePartnerStartActionCreator = () => ({
  type: PARTNER_SAVE_OR_UPDATE_START,
});

export const saveOrUpdatePartnerFinishActionCreator = (
  partner: PartnerType,
) => ({
  type: PARTNER_SAVE_OR_UPDATE_FINISH,
  payload: partner,
});

export const deletePartnerStartActionCreator = () => ({
  type: PARTNER_DELETE_START,
});

export const deletePartnerFinishActionCreator = (partner: PartnerType) => ({
  type: PARTNER_DELETE_FINISH,
  payload: partner,
});
