import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LocalUsersUpdate as LocalUsersUpdatePage } from '_modules/LocalUsers/screens';
import { getLocalUserById, getAllEstablishments } from '_redux/actions';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';

type UseParamsType = {
  id: string;
};

const LocalUsersUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { id } = useParams<UseParamsType>();

  useEffect(() => {
    dispatch(getLocalUserById(user?.partnerId!, parseInt(id)));

    dispatch(getAllEstablishments(user?.partnerId!));
  }, [id, dispatch, user]);

  return <LocalUsersUpdatePage partnerId={user?.partnerId!} />;
};

export default LocalUsersUpdate;
