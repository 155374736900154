import React, { useCallback } from 'react';

import { Button, PartnerStandardLayout } from '_components';
import { ActionFooter, Container } from './OrderFinished.styles';
import { useParams } from 'react-router-dom';
import { useRoute } from 'smiles-react-wallet-core/route';

const OrderFinished: React.FC = () => {
  const { orderId } = useParams();
  const { navigateTo } = useRoute();

  const goToOrder = useCallback(() => {
    navigateTo('Detalhes Transação', { id: orderId });
  }, [orderId, navigateTo]);

  return (
    <PartnerStandardLayout breadcrumbs={[]}>
      <Container>
        <h2>Venda concluída com sucesso</h2>

        <ActionFooter>
          <Button
            reverse
            text="Ir para a página da transação"
            onClick={goToOrder}
          />
        </ActionFooter>
      </Container>
    </PartnerStandardLayout>
  );
};

export default OrderFinished;
