import { EstablishmentType } from 'smiles-react-wallet-core/services/types';
import { IEstablishmentState } from '../types';

import {
  ESTABLISHMENT_CLEAR,
  ESTABLISHMENT_GET_ALL_START,
  ESTABLISHMENT_GET_ALL_FINISH,
  ESTABLISHMENT_GET_BY_ID_START,
  ESTABLISHMENT_GET_BY_ID_FINISH,
  ESTABLISHMENT_SAVE_OR_UPDATE_START,
  ESTABLISHMENT_SAVE_OR_UPDATE_FINISH,
  ESTABLISHMENT_DELETE_START,
  ESTABLISHMENT_DELETE_FINISH,
  ERROR_SHOW,
} from '../actionTypes';

const clearPartnerReducer = () => ({ ...establishmentInitialState });
const clearLoading = (_: any, state: any) => ({
  ...state,
  loadingEstablishment: false,
  savingEstablishment: false,
});

const getAllStartReducer = () => ({
  loadingEstablishment: true,
  establishmentList: [],
});
const getAllFinishReducer = (establishmentList: Array<EstablishmentType>) => ({
  loadingEstablishment: false,
  establishmentList,
});

const getByIdStartReducer = () => ({
  loadingEstablishment: true,
  establishmentDetails: undefined,
});
const getByIdFinishReducer = (establishmentDetails: EstablishmentType) => ({
  loadingEstablishment: false,
  establishmentDetails,
});

const saveOrUpdateStartReducer = () => ({
  savingEstablishment: true,
});
const saveOrUpdateFinishReducer = (
  establishment: EstablishmentType,
  { establishmentList }: IEstablishmentState,
) => ({
  savingEstablishment: false,
  establishmentDetails: undefined,
  establishmentList: [...establishmentList, establishment],
});

const deleteStartReducer = () => ({
  savingEstablishment: true,
});
const deleteFinishReducer = (establishmentDetails: EstablishmentType) => ({
  savingEstablishment: false,
  establishmentDetails,
});

export const establishmentInitialState: IEstablishmentState = {
  loadingEstablishment: false,
  savingEstablishment: false,
  establishmentDetails: undefined,
  establishmentList: [],
};

export const establishmentReducer = {
  [ERROR_SHOW]: clearLoading,
  [ESTABLISHMENT_CLEAR]: clearPartnerReducer,
  [ESTABLISHMENT_GET_ALL_START]: getAllStartReducer,
  [ESTABLISHMENT_GET_ALL_FINISH]: getAllFinishReducer,
  [ESTABLISHMENT_GET_BY_ID_START]: getByIdStartReducer,
  [ESTABLISHMENT_GET_BY_ID_FINISH]: getByIdFinishReducer,
  [ESTABLISHMENT_SAVE_OR_UPDATE_START]: saveOrUpdateStartReducer,
  [ESTABLISHMENT_SAVE_OR_UPDATE_FINISH]: saveOrUpdateFinishReducer,
  [ESTABLISHMENT_DELETE_START]: deleteStartReducer,
  [ESTABLISHMENT_DELETE_FINISH]: deleteFinishReducer,
};
