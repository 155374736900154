import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import Store from '_redux/store';
import { Normalize } from 'smiles-react-wallet-core/assets';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { PartnerProvider } from '_common';
import Providers from 'smiles-react-wallet-core/providers';
import { ProtectedRoute, DisabledUser } from '_components';

import {
  Login,
  NotFound,
  ForgotPassword,
  SystemUnavailable,
  CreatePasswordHome,
  CreatePasswordFinish,
} from '_modules';
import { RouteProvider } from 'smiles-react-wallet-core/route';
import { ROUTES } from './context/routes';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const App: React.FC = () => (
  <ReduxProvider store={Store}>
    <Providers>
      <PartnerProvider>
        <Normalize />
        <Router>
          <RouteProvider routes={ROUTES}>
            <Switch>
              {ROUTES.map(({ path, component: Component }: any) => (
                <ProtectedRoute
                  exact
                  key={path}
                  path={path}
                  component={Component}
                />
              ))}
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/logout"
                component={() => <Redirect to="/login" />}
              />
              <Route exact path="/senha/esqueci" component={ForgotPassword} />
              <Route
                exact
                path="/senha/reenvio/:token"
                component={CreatePasswordHome}
              />
              <Route
                exact
                path="/senha/reenvio"
                component={CreatePasswordFinish}
              />
              <Route exact path="/usuario/inativo" component={DisabledUser} />
              <ProtectedRoute
                exact
                path="/sistema-indisponivel"
                component={SystemUnavailable}
              />
              <Route component={NotFound} />
            </Switch>
          </RouteProvider>
        </Router>
      </PartnerProvider>
    </Providers>
  </ReduxProvider>
);

export default App;
