import { Dispatch } from 'redux';
import {
  CancellationInfo,
  FilterType,
} from 'smiles-react-wallet-core/services/types';

import * as API from 'smiles-react-wallet-core/services/api/transactions';
import {
  clearTransactionsActionCreator,
  getAllTransactionsStartActionCreator,
  getAllTransactionsFinishActionCreator,
  getTransactionByIdStartActionCreator,
  getTransactionByIdFinishActionCreator,
  getExpiringMilesByTransacionIdFinishActionCreator,
  getExpiringMilesByTransacionIdStartActionCreator,
  cancelTransacionFinishActionCreator,
  cancelTransacionStartActionCreator,
} from '../actionCreators';

/** CLEAR TRANSACTIONS */
export const clearTransactions = () => async (dispatch: Dispatch) =>
  dispatch(clearTransactionsActionCreator());

/** GET ALL TRANSACTIONS */
export const getAllTransactions = (
  filter: FilterType,
  handleTitle?: (value: any) => void,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(getAllTransactionsStartActionCreator());

    if (filter?.id) {
      const { data } = await API.getTransactionById(filter.id);
      dispatch(
        getAllTransactionsFinishActionCreator(data === '' ? [] : [data]),
      );

      handleTitle && handleTitle(data ? 1 : 0);
    } else {
      const { data } = await API.getTransactions(filter && filter);
      dispatch(getAllTransactionsFinishActionCreator(data));

      handleTitle && handleTitle(data.length);
    }
  } catch (err) {
    dispatch(clearTransactionsActionCreator());
    handleTitle && handleTitle(0);
  }
};

/** GET TRANSACTION BY ID */
export const getTransactionById = (id: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(getTransactionByIdStartActionCreator());

  const { data } = await API.getTransactionById(id);
  dispatch(getTransactionByIdFinishActionCreator(data));
};

/** GET EXPIRING MILES */
export const getExpiringMilesById = (id: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(getExpiringMilesByTransacionIdStartActionCreator());
  try {
    const { data } = await API.getExpiringMilesById(id);
    dispatch(getExpiringMilesByTransacionIdFinishActionCreator(data));
  } catch (err) {
    console.log('Transaction has no expired miles.');
  }
};

/** CANCEL TRANSACTION */
export const cancelTransaction = (
  navigateTo: any,
  cancellationInfo: CancellationInfo,
) => async (dispatch: Dispatch) => {
  dispatch(cancelTransacionStartActionCreator());

  try {
    const { data } = await API.cancelTransaction(cancellationInfo);
    dispatch(cancelTransacionFinishActionCreator(data));

    navigateTo('Cancelamento de transação finalizada', {
      id: cancellationInfo?.orderId,
    });
  } catch (err) {
    console.log(err);
  }
};
