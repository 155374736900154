import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EstablishmentType } from 'smiles-react-wallet-core/services/types';
import { useRoute } from 'smiles-react-wallet-core/route';

import {
  EstablishmentsFilter,
  EnhancedListing,
  PartnerStandardLayout,
} from '_components';
import { IStore } from '_redux/store';
import { getAllEstablishments } from '_redux/actions';

const initialOrderBy = {
  column: 'NAME',
  order: 'DESC',
};

interface EstablishmentsListProps {
  partnerId: number;
}

const EstablishmentsList: React.FC<EstablishmentsListProps> = ({
  partnerId,
}) => {
  const { navigateTo } = useRoute();
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const { establishmentList, loadingEstablishment } = useSelector(
    (state: IStore) => state.establishment,
  );
  const [filteredEstablishments, setFilteredEstablishments] = useState<
    EstablishmentType[]
  >(establishmentList);

  function handleDetails(id: number) {
    navigateTo('Detalhes do Estabelecimento', { id });
  }

  useEffect(() => {
    setFilteredEstablishments(establishmentList);
  }, [establishmentList]);

  useEffect(() => {
    if (orderBy !== initialOrderBy) {
      const order = orderBy.column.toUpperCase().concat(',', orderBy.order);
      dispatch(getAllEstablishments(partnerId, order));
    }
  }, [orderBy, setOrderBy, dispatch, partnerId]);

  return (
    <PartnerStandardLayout
      pageTitle="Estabelecimentos"
      isLoading={loadingEstablishment}
    >
      <EstablishmentsFilter
        establishments={establishmentList}
        setFilteredEstablishments={setFilteredEstablishments}
      />

      {establishmentList.length > 0 ? (
        <EnhancedListing
          list={filteredEstablishments}
          headers={{ name: 'Estabelecimentos', state: 'Estado', cnpj: 'CNPJ' }}
          fieldParser={{
            state: ({ address }) => `${address?.state || '--'}`,
          }}
          onClickDetails={({ id }: EstablishmentType) => handleDetails(id!)}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          hasPermission
        />
      ) : (
        <h1>Nenhum estabelecimento encontrado.</h1>
      )}
    </PartnerStandardLayout>
  );
};

export default EstablishmentsList;
