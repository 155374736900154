export const TRANSACTIONS_CLEAR = 'TRANSACTIONS_CLEAR';
export const TRANSACTIONS_GET_ALL_START = 'TRANSACTIONS_GET_ALL_START';
export const TRANSACTIONS_GET_ALL_FINISH = 'TRANSACTIONS_GET_ALL_FINISH';
export const TRANSACTION_GET_BY_ID_START = 'TRANSACTION_GET_BY_ID_START';
export const TRANSACTION_GET_BY_ID_FINISH = 'TRANSACTION_GET_BY_ID_FINISH';
export const EXPIRING_MILES_GET_BY_ID_START = 'EXPIRING_MILES_GET_BY_ID_START';
export const EXPIRING_MILES_GET_BY_ID_FINISH =
  'EXPIRING_MILES_GET_BY_ID_FINISH';
export const TRANSACTION_CANCEL_START = 'TRANSACTION_CANCEL_START';
export const TRANSACTION_CANCEL_FINISH = 'TRANSACTION_CANCEL_FINISH';
