import React, { Fragment } from 'react';

import { Container } from './ErrorBox.styles';
import { Button } from '_components';

export interface OrderErrorProps {
  icon: any;
  title: string;
  message: string;
  buttonText?: string;
  tryAgain?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ErrorBox: React.FC<OrderErrorProps> = ({
  icon,
  title,
  message,
  tryAgain,
  buttonText = 'Tentar novamente',
}) => {
  return (
    <Fragment>
      <Container>
        <h2>
          {title} {icon}
        </h2>

        <p>{message}</p>

        {tryAgain && <Button text={buttonText} reverse onClick={tryAgain} />}
      </Container>
    </Fragment>
  );
};

export default ErrorBox;
