import React, { useEffect, useCallback, createRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormikProps, Form } from 'formik';
import Recaptcha from 'reaptcha';

import { CreatePasswordFormType } from 'smiles-react-wallet-core/services/types';
import { Divider, Button } from '_components';
import {
  Container,
  PasswordValidations,
  ValidationSpan,
  RecaptchaWrapper,
} from './CreatePasswordForm.styles';
import { APPLICATION } from 'smiles-react-wallet-core/config';

const CreatePasswordForm: React.FC<FormikProps<CreatePasswordFormType>> = ({
  errors,
  values,
  handleChange,
  handleBlur,
}) => {
  const password = values.password;
  const recaptchaRef = createRef<any>();
  const onVerify = useCallback(
    (response: string) => {
      handleChange({
        target: {
          id: 'recaptchaStatus',
          name: 'recaptchaStatus',
          value: response,
        },
      });
    },
    [handleChange],
  );

  const onExpire = useCallback(
    () => {

      handleChange({
        target: {
          id: 'recaptchaStatus',
          name: 'recaptchaStatus',
          value: undefined,
        },
      });
    },
    [handleChange],
  );

  useEffect(() => {
    const upperCaseLetters = /[A-Z]/g;
    const lowerCaseLetters = /[a-z]/g;
    const numbers = /[0-9]/g;
    const symbols = /[!@#$%^&*]/g


    function verifySequence(password: any){
      // Check for sequential numerical characters
      for(const i in password) {
        if (+password[+i+1] == +password[i]+1) return false
        if (password[+i+1] == password[i]-1) return false
      }

      return true;
    }

    function validatePassword() {
      /** Check length */
      if (password.length >= 8 && password.length <= 10) {
        document.getElementById('length')?.classList.remove('invalid');
        document.getElementById('length')?.classList.add('valid');
      } else {
        document.getElementById('length')?.classList.remove('valid');
        document.getElementById('length')?.classList.add('invalid');
      }

      /** Check lowercase */
      if (password.match(lowerCaseLetters)) {
        document.getElementById('lowercase')?.classList.remove('invalid');
        document.getElementById('lowercase')?.classList.add('valid');
      } else {
        document.getElementById('lowercase')?.classList.remove('valid');
        document.getElementById('lowercase')?.classList.add('invalid');
      }

      /** Check uppercase */
      if (password.match(upperCaseLetters)) {
        document.getElementById('uppercase')?.classList.remove('invalid');
        document.getElementById('uppercase')?.classList.add('valid');
      } else {
        document.getElementById('uppercase')?.classList.remove('valid');
        document.getElementById('uppercase')?.classList.add('invalid');
      }

      /** Check number */
      if (password.match(numbers) && verifySequence(password)) {
        document.getElementById('number')?.classList.remove('invalid');
        document.getElementById('number')?.classList.add('valid');
      } else {
        document.getElementById('number')?.classList.remove('valid');
        document.getElementById('number')?.classList.add('invalid');
      }

      /** Check symbols */
      if (password.match(symbols)) {
        document.getElementById('symbol')?.classList.remove('invalid');
        document.getElementById('symbol')?.classList.add('valid');
      } else {
        document.getElementById('symbol')?.classList.remove('valid');
        document.getElementById('symbol')?.classList.add('invalid');
      }
    }

    validatePassword();
  }, [password]);

  return (
    <Container>
      <Form>
        <TextField
          id="password"
          label="Senha"
          type="password"
          autoComplete="off"
          error={!!errors.password}
          helperText={errors.password}
          spellCheck="false"
          variant="outlined"
          onBlur={handleBlur}
          inputProps={{ maxLength: 10 }}
          onChange={handleChange}
          value={values.password}
          fullWidth
        />

        <TextField
          id="confirmPassword"
          label="Confirme sua senha"
          type="password"
          autoComplete="off"
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          spellCheck="false"
          variant="outlined"
          onBlur={handleBlur}
          inputProps={{ maxLength: 10 }}
          onChange={handleChange}
          value={values.confirmPassword}
          fullWidth
        />

        <PasswordValidations>
          <span>Para sua segurança, sua senha deve possuir:</span>
          <ValidationSpan id="length">Entre 8 a 10 caracteres</ValidationSpan>
          <ValidationSpan id="uppercase">Letra maiúscula</ValidationSpan>
          <ValidationSpan id="lowercase">Letra minúscula</ValidationSpan>
          <ValidationSpan id="number">Números não sequenciais</ValidationSpan>
          <ValidationSpan id="symbol">Caracteres Especiais</ValidationSpan>
        </PasswordValidations>

        <Divider spacing={30} />

        <RecaptchaWrapper highlight={!!errors.recaptchaStatus}>
          <Recaptcha
            ref={recaptchaRef}
            sitekey={APPLICATION.get('AUTH0_CAPTCHA_KEY')}
            onVerify={onVerify}
            onExpire={onExpire}
          />
        </RecaptchaWrapper>

        <Button
          type="submit"
          disabled={Object.keys(errors).length > 0}
          text="Cadastrar"
        />
      </Form>
    </Container>
  );
};

export default CreatePasswordForm;
