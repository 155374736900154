import styled from 'styled-components';
import { COLORS, SPACING } from 'smiles-react-wallet-core/config';

export const Container = styled.div`
  display: flex;

  .establishment-filter-wapper {
    width: 400px;
  }
`;

export const StateFilterWrapper = styled.div`
  display: flex;
  align-items: center;

  border-left: 1px solid ${COLORS.lightGrey};
  margin-left: ${SPACING.large}px;
  padding-left: ${SPACING.large}px;

  label {
    margin-right: 15px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    width: 100px;
  }

  .styled-menuItem {
    color: ${COLORS.grey};
  }
`;
