import styled from 'styled-components';
import { COLORS } from 'smiles-react-wallet-core/config';

interface ConditionalDisplay {
  isVisible: boolean;
}

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  h1 {
    font-size: 25px;
    color: ${COLORS.grey};
  }
`;

export const CancelationContainer = styled.div`
  margin-top: 50px;

  span {
    color: ${COLORS.grey};
    font-weight: 600;
  }
`;

export const PartnerPhrases = styled.div<ConditionalDisplay>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const CancelButtonContainer = styled.div<ConditionalDisplay>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
`;

export const FormWrapper = styled.div<ConditionalDisplay>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const AlertSpan = styled.span``;
