import styled, { css } from 'styled-components';
import { COLORS, THEME } from 'smiles-react-wallet-core/config';

type Padding = {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  smallerFont?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 25px 45px 20px;
  width: 100%;
  max-width: 360px;
  border-radius: 3px;
  flex: 1;
  background: ${COLORS.white};
  input {
    padding: 14.5px 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.tabBorder};
    border-radius: 3px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }
`;

export const Logo = styled.img`
  align-self: center;
  width: 120px;
  height: 50px;
`;

export const DashboardName = styled.div<Padding>`
  color: ${THEME.fontColor};
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.43px;
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 25px;
    `};
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: 28px;
    `}
  ${({ smallerFont }) =>
    smallerFont &&
    css`
      font-size: 16px;
      font-weight: 200;
    `}
`;
