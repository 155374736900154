import React, { Fragment } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { TableWrapper } from './OrdersNewTable.styles';
import { OrdersNewTableProps } from './OrdersNewTable.types';
import { productTypes } from 'smiles-react-wallet-core/utils';
import { AmountInfo } from 'smiles-react-wallet-core/services/types';

type OrdersNewTableBodyProps = {
  amount: Array<AmountInfo>;
};

const formatter = new Intl.NumberFormat('pt-BR');
const moneyFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
});

const OrdersNewTableBody: React.FC<OrdersNewTableBodyProps> = ({ amount }) => {
  if (!amount) {
    return null;
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell variant="head" className="textDivisor" colSpan={2}>
          Valor em milhas
        </TableCell>
      </TableRow>
      {amount.map((tier) => (
        <TableRow key={tier.tier}>
          <TableCell variant="head">{tier.tier}:</TableCell>
          <TableCell>{formatter.format(tier.miles)}</TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

const OrdersNewTable: React.FC<OrdersNewTableProps> = ({ order }) => {
  const local = productTypes.filter((pt) => pt.value === order.productType)[0]
    .label;

  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Produto / serviço:</TableCell>
              <TableCell>{local}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Valor em reais (R$):</TableCell>
              <TableCell>{moneyFormatter.format(order.cost)}</TableCell>
            </TableRow>
            <OrdersNewTableBody amount={order.amount} />
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default OrdersNewTable;
