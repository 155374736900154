import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllLocalUsers } from '_redux/actions';
import { LocalUsersList, LocalUsersWelcome } from '_modules/LocalUsers/screens';
import useUser from 'smiles-react-wallet-core/authentication/hooks/useUser';
import { IStore } from '_redux/store';

const LocalUsersHome: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { localUsers } = useSelector((state: IStore) => state.localUsers);

  useEffect(() => {
    dispatch(getAllLocalUsers(user?.partnerId!));
  }, [dispatch, user]);

  return localUsers.length > 0 ? (
    <LocalUsersList partnerId={user?.partnerId!} />
  ) : (
    <LocalUsersWelcome />
  );
};

export default LocalUsersHome;
