import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  CreatePasswordSchema,
  initialCreatePasswordValues,
} from 'smiles-react-wallet-core/utils/models';
import { CreatePasswordFormType } from 'smiles-react-wallet-core/services/types';
import { CreatePasswordForm, LoginWrapper } from '_components';
import { Smiles_Orange } from 'smiles-react-wallet-core/assets';

import { Container, Logo, DashboardName } from './CreatePassword.styles';
import { changePassword } from '_redux/actions';
import { IStore } from '_redux/store';

const CreatePassword: React.FC = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useSelector((state: IStore) => state.resetPassword);

  function handleSubmit(values: CreatePasswordFormType) {
    dispatch(
      changePassword(history, {
        token,
        password: values.password,
      },
      values.recaptchaStatus!,
      ),
    );
  }

  return (
    <LoginWrapper>
      <Container>
        <Logo src={Smiles_Orange} />
        <DashboardName paddingTop fontWeigth>
          Portal Parceiro
        </DashboardName>

        <DashboardName paddingBottom paddingTop smallFont>
          Olá. <br /> Por favor, cadastre sua senha de acesso ao Portal
          Parceiro.
        </DashboardName>
        <Formik
          initialValues={initialCreatePasswordValues}
          validationSchema={CreatePasswordSchema}
          onSubmit={handleSubmit}
          component={CreatePasswordForm}
        />
      </Container>
    </LoginWrapper>
  );
};

export default CreatePassword;
