import React, { ChangeEvent } from 'react';
import 'moment/locale/pt-br';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Form, FormikProps } from 'formik';

import { FilterType } from 'smiles-react-wallet-core/services/types';
import { Divider, Button, Calendar } from 'smiles-react-wallet-core/components';
import {
  Container,
  TopFilters,
  BottomFilters,
  ButtonContainer,
} from './PartnerTransactionsFilter.styles';

const PartnerTransactionsFilter: React.FC<FormikProps<FilterType>> = ({
  errors,
  values,
  handleChange,
  setFieldValue,
}) => {
  function handleResetField(
    fieldId: string,
    fieldValue: number | string | undefined,
  ) {
    if (fieldValue === '') {
      setFieldValue(fieldId, undefined);
    }
  }

  function handleCheckChange(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    const { name } = event.target;
    setFieldValue(name, checked);

    const index = values.status?.indexOf(name)!;

    if (index < 0) {
      let aux = [...values.status!];
      aux.push(name);
      setFieldValue('status', Array.from(new Set(aux)));
    } else {
      let aux = [...values.status!];
      aux.splice(index, 1);
      setFieldValue('status', aux);
    }
  }

  return (
    <Container>
      <strong className="container-title">Buscar Transação</strong>
      <Form>
        <TopFilters>
          <div className="inputWrapper">
            <TextField
              id="id"
              label="ID da transação"
              error={!!errors.id}
              helperText={errors.id ? errors.id : ' '}
              autoComplete="off"
              spellCheck="false"
              value={values.id}
              inputProps={{ maxLength: 100 }}
              onBlur={() => handleResetField('id', values.id)}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="inputWrapper">
            <TextField
              id="memberNumber"
              name="memberNumber"
              label="Número Smiles"
              error={!!errors.memberNumber}
              helperText={errors.memberNumber ? errors.memberNumber : ' '}
              value={values.memberNumber}
              onBlur={() =>
                handleResetField('memberNumber', values.memberNumber)
              }
              onChange={handleChange}
              inputProps={{ maxLength: 9 }}
              autoComplete="off"
              spellCheck="false"
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="inputWrapper">
            <TextField
              id="operatorEmail"
              name="operatorEmail"
              label="E-mail do operador"
              error={!!errors.operatorEmail}
              helperText={errors.operatorEmail ? errors.operatorEmail : ' '}
              value={values.operatorEmail}
              inputProps={{ maxLength: 100 }}
              onBlur={() =>
                handleResetField('operatorEmail', values.operatorEmail)
              }
              onChange={handleChange}
              autoComplete="off"
              spellCheck="false"
              variant="outlined"
              fullWidth
            />
          </div>
        </TopFilters>

        <BottomFilters>
          <Calendar values={values} setFieldValue={setFieldValue} />

          <div className="formGroup">
            <strong>Status</strong>

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.processed}
                  onChange={handleCheckChange}
                  name="processed"
                />
              }
              label="Aprovado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.canceled}
                  onChange={handleCheckChange}
                  name="canceled"
                />
              }
              label="Cancelado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.canceled_by_error}
                  onChange={handleCheckChange}
                  name="canceled_by_error"
                />
              }
              label="Erro"
            />
          </div>
        </BottomFilters>

        <Divider color="#FF5A00" />

        <ButtonContainer>
          <Button type="submit" text="Buscar" />
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default PartnerTransactionsFilter;
